import { jsx as _jsx } from "react/jsx-runtime";
// Copyright 2022 The Parca Authors
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { Input } from '@parca/components';
import { selectFilterByFunction, setFilterByFunction, useAppDispatch, useAppSelector, } from '@parca/store';
import { Icon } from '@iconify/react';
import { useCallback, useMemo, useState } from 'react';
var FilterByFunctionButton = function () {
    var dispatch = useAppDispatch();
    var storeVal = useAppSelector(selectFilterByFunction);
    var _a = useState(storeVal !== null && storeVal !== void 0 ? storeVal : ''), value = _a[0], setValue = _a[1];
    var isClearAction = useMemo(function () {
        return value === storeVal && value != null;
    }, [value, storeVal]);
    var onAction = useCallback(function () {
        dispatch(setFilterByFunction(isClearAction ? undefined : value));
        if (isClearAction) {
            setValue('');
        }
    }, [dispatch, isClearAction, value]);
    return (_jsx(Input, { placeholder: "Filter by function", className: "text-sm", onAction: onAction, onChange: function (e) { return setValue(e.target.value); }, value: value, onBlur: function () { return setValue(storeVal !== null && storeVal !== void 0 ? storeVal : ''); }, actionIcon: isClearAction ? _jsx(Icon, { icon: "ep:circle-close" }) : _jsx(Icon, { icon: "ep:arrow-right" }) }));
};
export default FilterByFunctionButton;
