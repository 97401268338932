// @generated by protobuf-ts 2.8.2 with parameter long_type_string,generate_dependencies
// @generated from protobuf file "parca/profilestore/v1alpha1/profilestore.proto" (package "parca.profilestore.v1alpha1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Duration } from "../../../google/protobuf/duration";
import { Timestamp } from "../../../google/protobuf/timestamp";
// @generated message type with reflection information, may provide speed optimized methods
class WriteRawRequest$Type extends MessageType {
    constructor() {
        super("parca.profilestore.v1alpha1.WriteRawRequest", [
            { no: 1, name: "tenant", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "series", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RawProfileSeries },
            { no: 3, name: "normalized", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { tenant: "", series: [], normalized: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tenant = 1 [deprecated = true];*/ 1:
                    message.tenant = reader.string();
                    break;
                case /* repeated parca.profilestore.v1alpha1.RawProfileSeries series */ 2:
                    message.series.push(RawProfileSeries.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool normalized */ 3:
                    message.normalized = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string tenant = 1 [deprecated = true]; */
        if (message.tenant !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tenant);
        /* repeated parca.profilestore.v1alpha1.RawProfileSeries series = 2; */
        for (let i = 0; i < message.series.length; i++)
            RawProfileSeries.internalBinaryWrite(message.series[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool normalized = 3; */
        if (message.normalized !== false)
            writer.tag(3, WireType.Varint).bool(message.normalized);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.profilestore.v1alpha1.WriteRawRequest
 */
export const WriteRawRequest = new WriteRawRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WriteRawResponse$Type extends MessageType {
    constructor() {
        super("parca.profilestore.v1alpha1.WriteRawResponse", []);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        return target !== null && target !== void 0 ? target : this.create();
    }
    internalBinaryWrite(message, writer, options) {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.profilestore.v1alpha1.WriteRawResponse
 */
export const WriteRawResponse = new WriteRawResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RawProfileSeries$Type extends MessageType {
    constructor() {
        super("parca.profilestore.v1alpha1.RawProfileSeries", [
            { no: 1, name: "labels", kind: "message", T: () => LabelSet },
            { no: 2, name: "samples", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RawSample }
        ]);
    }
    create(value) {
        const message = { samples: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* parca.profilestore.v1alpha1.LabelSet labels */ 1:
                    message.labels = LabelSet.internalBinaryRead(reader, reader.uint32(), options, message.labels);
                    break;
                case /* repeated parca.profilestore.v1alpha1.RawSample samples */ 2:
                    message.samples.push(RawSample.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* parca.profilestore.v1alpha1.LabelSet labels = 1; */
        if (message.labels)
            LabelSet.internalBinaryWrite(message.labels, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated parca.profilestore.v1alpha1.RawSample samples = 2; */
        for (let i = 0; i < message.samples.length; i++)
            RawSample.internalBinaryWrite(message.samples[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.profilestore.v1alpha1.RawProfileSeries
 */
export const RawProfileSeries = new RawProfileSeries$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Label$Type extends MessageType {
    constructor() {
        super("parca.profilestore.v1alpha1.Label", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { name: "", value: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.profilestore.v1alpha1.Label
 */
export const Label = new Label$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LabelSet$Type extends MessageType {
    constructor() {
        super("parca.profilestore.v1alpha1.LabelSet", [
            { no: 1, name: "labels", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Label }
        ]);
    }
    create(value) {
        const message = { labels: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.profilestore.v1alpha1.Label labels */ 1:
                    message.labels.push(Label.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.profilestore.v1alpha1.Label labels = 1; */
        for (let i = 0; i < message.labels.length; i++)
            Label.internalBinaryWrite(message.labels[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.profilestore.v1alpha1.LabelSet
 */
export const LabelSet = new LabelSet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RawSample$Type extends MessageType {
    constructor() {
        super("parca.profilestore.v1alpha1.RawSample", [
            { no: 1, name: "raw_profile", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value) {
        const message = { rawProfile: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes raw_profile */ 1:
                    message.rawProfile = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* bytes raw_profile = 1; */
        if (message.rawProfile.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.rawProfile);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.profilestore.v1alpha1.RawSample
 */
export const RawSample = new RawSample$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgentsRequest$Type extends MessageType {
    constructor() {
        super("parca.profilestore.v1alpha1.AgentsRequest", []);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        return target !== null && target !== void 0 ? target : this.create();
    }
    internalBinaryWrite(message, writer, options) {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.profilestore.v1alpha1.AgentsRequest
 */
export const AgentsRequest = new AgentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgentsResponse$Type extends MessageType {
    constructor() {
        super("parca.profilestore.v1alpha1.AgentsResponse", [
            { no: 1, name: "agents", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Agent }
        ]);
    }
    create(value) {
        const message = { agents: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.profilestore.v1alpha1.Agent agents */ 1:
                    message.agents.push(Agent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.profilestore.v1alpha1.Agent agents = 1; */
        for (let i = 0; i < message.agents.length; i++)
            Agent.internalBinaryWrite(message.agents[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.profilestore.v1alpha1.AgentsResponse
 */
export const AgentsResponse = new AgentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Agent$Type extends MessageType {
    constructor() {
        super("parca.profilestore.v1alpha1.Agent", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "last_error", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "last_push", kind: "message", T: () => Timestamp },
            { no: 4, name: "last_push_duration", kind: "message", T: () => Duration }
        ]);
    }
    create(value) {
        const message = { id: "", lastError: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string last_error */ 2:
                    message.lastError = reader.string();
                    break;
                case /* google.protobuf.Timestamp last_push */ 3:
                    message.lastPush = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.lastPush);
                    break;
                case /* google.protobuf.Duration last_push_duration */ 4:
                    message.lastPushDuration = Duration.internalBinaryRead(reader, reader.uint32(), options, message.lastPushDuration);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string last_error = 2; */
        if (message.lastError !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.lastError);
        /* google.protobuf.Timestamp last_push = 3; */
        if (message.lastPush)
            Timestamp.internalBinaryWrite(message.lastPush, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Duration last_push_duration = 4; */
        if (message.lastPushDuration)
            Duration.internalBinaryWrite(message.lastPushDuration, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.profilestore.v1alpha1.Agent
 */
export const Agent = new Agent$Type();
/**
 * @generated ServiceType for protobuf service parca.profilestore.v1alpha1.ProfileStoreService
 */
export const ProfileStoreService = new ServiceType("parca.profilestore.v1alpha1.ProfileStoreService", [
    { name: "WriteRaw", options: { "google.api.http": { post: "/profiles/writeraw", body: "*" } }, I: WriteRawRequest, O: WriteRawResponse }
]);
/**
 * @generated ServiceType for protobuf service parca.profilestore.v1alpha1.AgentsService
 */
export const AgentsService = new ServiceType("parca.profilestore.v1alpha1.AgentsService", [
    { name: "Agents", options: { "google.api.http": { get: "/agents" } }, I: AgentsRequest, O: AgentsResponse }
]);
