// Copyright 2022 The Parca Authors
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import profileReducer from './slices/profileSlice';
import uiReducer from './slices/uiSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistStore, } from 'redux-persist';
var rootReducer = combineReducers({
    ui: uiReducer,
    profile: profileReducer,
});
var slicesToPersist = ['ui'];
var persistConfig = {
    key: 'root',
    version: 1,
    storage: storage,
    whitelist: slicesToPersist,
};
var persistedReducer = persistReducer(persistConfig, rootReducer);
var store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        });
    },
});
var defaultExports = function () {
    var persistor = persistStore(store);
    return { store: store, persistor: persistor };
};
export default defaultExports;
