var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
var DEFAULT_ICON = (_jsx("svg", __assign({ width: "64", height: "41", viewBox: "0 0 64 41", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("g", __assign({ transform: "translate(0 1)", fill: "none", fillRule: "evenodd" }, { children: [_jsx("ellipse", { fill: "#F5F5F5", cx: "32", cy: "33", rx: "32", ry: "7" }), _jsxs("g", __assign({ fillRule: "nonzero", stroke: "#D9D9D9" }, { children: [_jsx("path", { d: "M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" }), _jsx("path", { d: "M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z", fill: "#FAFAFA" })] }))] })) })));
var EmptyState = function (_a) {
    var title = _a.title, icon = _a.icon, body = _a.body, isEmpty = _a.isEmpty, children = _a.children;
    return isEmpty ? (_jsx("div", __assign({ className: "flex justify-center items-center flex-col h-64" }, { children: _jsxs(_Fragment, { children: [icon !== null && icon !== void 0 ? icon : DEFAULT_ICON, _jsx("p", __assign({ className: "flex items-center justify-center text-xl p-4 text-gray-500" }, { children: title !== null && title !== void 0 ? title : 'No data available' })), Boolean(body) && (_jsx("div", __assign({ className: "flex items-center justify-center p-1 text-gray-500 text-sm" }, { children: body })))] }) }))) : (_jsx(_Fragment, { children: children }));
};
export default EmptyState;
