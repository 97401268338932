var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// Copyright 2022 The Parca Authors
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { pointer } from 'd3-selection';
import { formatForTimespan } from '@parca/functions/time';
import { timeFormat } from '..';
import { cutToMaxStringLength } from '@parca/functions/string';
import throttle from 'lodash.throttle';
import { usePopper } from 'react-popper';
import { valueFormatter, formatDate, sanitizeHighlightedValues } from '@parca/functions';
import { DateTimeRange } from '@parca/components';
import { useContainerDimensions } from '@parca/dynamicsize';
import useIsShiftDown from '@parca/components/src/hooks/useIsShiftDown';
import MetricsSeries from '../MetricsSeries';
import MetricsCircle from '../MetricsCircle';
var MetricsGraph = function (_a) {
    var data = _a.data, from = _a.from, to = _a.to, profile = _a.profile, onSampleClick = _a.onSampleClick, onLabelClick = _a.onLabelClick, setTimeRange = _a.setTimeRange, sampleUnit = _a.sampleUnit;
    var _b = useContainerDimensions(), ref = _b.ref, dimensions = _b.dimensions;
    return (_jsx("div", __assign({ ref: ref }, { children: _jsx(RawMetricsGraph, { data: data, from: from, to: to, profile: profile, onSampleClick: onSampleClick, onLabelClick: onLabelClick, setTimeRange: setTimeRange, sampleUnit: sampleUnit, width: dimensions === null || dimensions === void 0 ? void 0 : dimensions.width }) })));
};
export default MetricsGraph;
export var parseValue = function (value) {
    var val = parseFloat(value);
    // "+Inf", "-Inf", "+Inf" will be parsed into NaN by parseFloat(). They
    // can't be graphed, so show them as gaps (null).
    return isNaN(val) ? null : val;
};
var lineStroke = '1px';
var lineStrokeHover = '2px';
function generateGetBoundingClientRect(contextElement, x, y) {
    if (x === void 0) { x = 0; }
    if (y === void 0) { y = 0; }
    var domRect = contextElement.getBoundingClientRect();
    return function () {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return ({
            width: 0,
            height: 0,
            top: domRect.y + y,
            left: domRect.x + x,
            right: domRect.x + x,
            bottom: domRect.y + y,
        });
    };
}
var virtualElement = {
    getBoundingClientRect: function () {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return {
            width: 0,
            height: 0,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        };
    },
};
export var MetricsTooltip = function (_a) {
    var x = _a.x, y = _a.y, highlighted = _a.highlighted, onLabelClick = _a.onLabelClick, contextElement = _a.contextElement, sampleUnit = _a.sampleUnit;
    var _b = useState(null), popperElement = _b[0], setPopperElement = _b[1];
    var _c = usePopper(virtualElement, popperElement, {
        placement: 'auto-start',
        strategy: 'absolute',
        modifiers: [
            {
                name: 'preventOverflow',
                options: {
                    tether: false,
                    altAxis: true,
                },
            },
            {
                name: 'offset',
                options: {
                    offset: [30, 30],
                },
            },
        ],
    }), styles = _c.styles, attributes = _c.attributes, popperProps = __rest(_c, ["styles", "attributes"]);
    var update = popperProps.update;
    useEffect(function () {
        if (contextElement != null) {
            virtualElement.getBoundingClientRect = generateGetBoundingClientRect(contextElement, x, y);
            void (update === null || update === void 0 ? void 0 : update());
        }
    }, [x, y, contextElement, update]);
    var nameLabel = highlighted === null || highlighted === void 0 ? void 0 : highlighted.labels.find(function (e) { return e.name === '__name__'; });
    var highlightedNameLabel = nameLabel !== undefined ? nameLabel : { name: '', value: '' };
    return (_jsx("div", __assign({ ref: setPopperElement, style: styles.popper }, attributes.popper, { className: "z-10" }, { children: _jsx("div", __assign({ className: "flex max-w-md" }, { children: _jsx("div", __assign({ className: "m-auto" }, { children: _jsx("div", __assign({ className: "border-gray-300 dark:border-gray-500 bg-gray-50 dark:bg-gray-900 rounded-lg p-3 shadow-lg opacity-90", style: { borderWidth: 1 } }, { children: _jsx("div", __assign({ className: "flex flex-row" }, { children: _jsxs("div", __assign({ className: "ml-2 mr-6" }, { children: [_jsx("span", __assign({ className: "font-semibold" }, { children: highlightedNameLabel.value })), _jsx("span", __assign({ className: "block text-gray-700 dark:text-gray-300 my-2" }, { children: _jsx("table", __assign({ className: "table-auto" }, { children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", __assign({ className: "w-1/4" }, { children: "Value" })), _jsx("td", __assign({ className: "w-3/4" }, { children: valueFormatter(highlighted.value, sampleUnit, 1) }))] }), _jsxs("tr", { children: [_jsx("td", __assign({ className: "w-1/4" }, { children: "At" })), _jsx("td", __assign({ className: "w-3/4" }, { children: formatDate(highlighted.timestamp, timeFormat) }))] })] }) })) })), _jsx("span", __assign({ className: "block text-gray-500 my-2" }, { children: highlighted.labels
                                        .filter(function (label) { return label.name !== '__name__'; })
                                        .map(function (label) {
                                        return (_jsx("button", __assign({ type: "button", className: "inline-block rounded-lg text-gray-700 bg-gray-200 dark:bg-gray-700 dark:text-gray-400 px-2 py-1 text-xs font-bold mr-3", onClick: function () { return onLabelClick(label.name, label.value); } }, { children: cutToMaxStringLength("".concat(label.name, "=\"").concat(label.value, "\""), 37) }), label.name));
                                    }) })), _jsx("span", __assign({ className: "block text-gray-500 text-xs" }, { children: "Hold shift and click label to add to query." }))] })) })) })) })) })) })));
};
export var RawMetricsGraph = function (_a) {
    var data = _a.data, from = _a.from, to = _a.to, profile = _a.profile, onSampleClick = _a.onSampleClick, onLabelClick = _a.onLabelClick, setTimeRange = _a.setTimeRange, width = _a.width, sampleUnit = _a.sampleUnit;
    var graph = useRef(null);
    var _b = useState(false), dragging = _b[0], setDragging = _b[1];
    var _c = useState(false), hovering = _c[0], setHovering = _c[1];
    var _d = useState(-1), relPos = _d[0], setRelPos = _d[1];
    var _e = useState([0, 0]), pos = _e[0], setPos = _e[1];
    var metricPointRef = useRef(null);
    var isShiftDown = useIsShiftDown();
    var time = parseFloat(profile === null || profile === void 0 ? void 0 : profile.HistoryParams().time);
    if (width === undefined || width == null) {
        width = 0;
    }
    var height = Math.min(width / 2.5, 400);
    var margin = 50;
    var marginRight = 20;
    var series = data.reduce(function (agg, s) {
        if (s.labelset !== undefined) {
            agg.push({
                metric: s.labelset.labels,
                values: s.samples.reduce(function (agg, d) {
                    if (d.timestamp !== undefined && d.value !== undefined) {
                        var t = (+d.timestamp.seconds * 1e9 + d.timestamp.nanos) / 1e6; // https://github.com/microsoft/TypeScript/issues/5710#issuecomment-157886246
                        agg.push([t, parseFloat(d.value)]);
                    }
                    return agg;
                }, []),
            });
        }
        return agg;
    }, []);
    var extentsY = series.map(function (s) {
        return d3.extent(s.values, function (d) {
            return d[1];
        });
    });
    var minY = d3.min(extentsY, function (d) {
        return d[0];
    });
    var maxY = d3.max(extentsY, function (d) {
        return d[1];
    });
    /* Scale */
    var xScale = d3
        .scaleUtc()
        .domain([from, to])
        .range([0, width - margin - marginRight]);
    var yScale = d3
        .scaleLinear()
        // tslint:disable-next-line
        .domain([minY, maxY])
        .range([height - margin, 0]);
    var color = d3.scaleOrdinal(d3.schemeCategory10);
    var l = d3.line(function (d) { return xScale(d[0]); }, function (d) { return yScale(d[1]); });
    var getClosest = function () {
        var closestPointPerSeries = series.map(function (s) {
            var distances = s.values.map(function (d) {
                var x = xScale(d[0]);
                var y = yScale(d[1]);
                return Math.sqrt(Math.pow(pos[0] - x, 2) + Math.pow(pos[1] - y, 2));
            });
            var pointIndex = d3.minIndex(distances);
            var minDistance = distances[pointIndex];
            return {
                pointIndex: pointIndex,
                distance: minDistance,
            };
        });
        var closestSeriesIndex = d3.minIndex(closestPointPerSeries, function (s) { return s.distance; });
        var pointIndex = closestPointPerSeries[closestSeriesIndex].pointIndex;
        var point = series[closestSeriesIndex].values[pointIndex];
        return {
            seriesIndex: closestSeriesIndex,
            labels: series[closestSeriesIndex].metric,
            timestamp: point[0],
            value: point[1],
            x: xScale(point[0]),
            y: yScale(point[1]),
        };
    };
    var highlighted = getClosest();
    var onMouseDown = function (e) {
        // only left mouse button
        if (e.button !== 0) {
            return;
        }
        // X/Y coordinate array relative to svg
        var rel = pointer(e);
        var xCoordinate = rel[0];
        var xCoordinateWithoutMargin = xCoordinate - margin;
        if (xCoordinateWithoutMargin >= 0) {
            setRelPos(xCoordinateWithoutMargin);
            setDragging(true);
        }
        e.stopPropagation();
        e.preventDefault();
    };
    var openClosestProfile = function () {
        if (highlighted != null) {
            onSampleClick(Math.round(highlighted.timestamp), highlighted.value, sanitizeHighlightedValues(highlighted.labels) // When a user clicks on any sample in the graph, replace single `\` in the `labelValues` string with doubles `\\` if available.
            );
        }
    };
    var onMouseUp = function (e) {
        setDragging(false);
        if (relPos === -1) {
            // MouseDown happened outside of this element.
            return;
        }
        // This is a normal click. We tolerate tiny movements to still be a
        // click as they can occur when clicking based on user feedback.
        if (Math.abs(relPos - pos[0]) <= 1) {
            openClosestProfile();
            setRelPos(-1);
            return;
        }
        var firstTime = xScale.invert(relPos).valueOf();
        var secondTime = xScale.invert(pos[0]).valueOf();
        if (firstTime > secondTime) {
            setTimeRange(DateTimeRange.fromAbsoluteDates(secondTime, firstTime));
        }
        else {
            setTimeRange(DateTimeRange.fromAbsoluteDates(firstTime, secondTime));
        }
        setRelPos(-1);
        e.stopPropagation();
        e.preventDefault();
    };
    var throttledSetPos = throttle(setPos, 20);
    var onMouseMove = function (e) {
        // X/Y coordinate array relative to svg
        var rel = pointer(e);
        var xCoordinate = rel[0];
        var xCoordinateWithoutMargin = xCoordinate - margin;
        var yCoordinate = rel[1];
        var yCoordinateWithoutMargin = yCoordinate - margin;
        if (!isShiftDown) {
            throttledSetPos([xCoordinateWithoutMargin, yCoordinateWithoutMargin]);
        }
    };
    var findSelectedProfile = function () {
        if (profile == null) {
            return null;
        }
        var s = null;
        var seriesIndex = -1;
        outer: for (var i = 0; i < series.length; i++) {
            var keys = profile.labels.map(function (e) { return e.name; });
            var _loop_1 = function (j) {
                var labelName = keys[j];
                var label = series[i].metric.find(function (e) { return e.name === labelName; });
                if (label === undefined) {
                    return "continue-outer";
                }
                if (profile.labels[j].value !== label.value) {
                    return "continue-outer";
                }
            };
            for (var j = 0; j < keys.length; j++) {
                var state_1 = _loop_1(j);
                switch (state_1) {
                    case "continue-outer": continue outer;
                }
            }
            seriesIndex = i;
            s = series[i];
        }
        if (s == null) {
            return null;
        }
        // Find the sample that matches the timestamp
        var sample = s.values.find(function (v) {
            return Math.round(v[0]) === time;
        });
        if (sample === undefined) {
            return null;
        }
        return {
            labels: [],
            seriesIndex: seriesIndex,
            timestamp: sample[0],
            value: sample[1],
            x: xScale(sample[0]),
            y: yScale(sample[1]),
        };
    };
    var selected = findSelectedProfile();
    return (_jsxs(_Fragment, { children: [highlighted != null && hovering && !dragging && pos[0] !== 0 && pos[1] !== 0 && (_jsx("div", __assign({ onMouseMove: onMouseMove, onMouseEnter: function () { return setHovering(true); }, onMouseLeave: function () { return setHovering(false); } }, { children: _jsx(MetricsTooltip, { x: pos[0] + margin, y: pos[1] + margin, highlighted: highlighted, onLabelClick: onLabelClick, contextElement: graph.current, sampleUnit: sampleUnit }) }))), _jsx("div", __assign({ ref: graph, onMouseEnter: function () {
                    setHovering(true);
                }, onMouseLeave: function () { return setHovering(false); } }, { children: _jsxs("svg", __assign({ width: "".concat(width, "px"), height: "".concat(height + margin, "px"), onMouseDown: onMouseDown, onMouseUp: onMouseUp, onMouseMove: onMouseMove }, { children: [_jsx("g", __assign({ transform: "translate(".concat(margin, ", 0)") }, { children: dragging && (_jsx("g", __assign({ className: "zoom-time-rect" }, { children: _jsx("rect", { className: "bar", x: pos[0] - relPos < 0 ? pos[0] : relPos, y: 0, height: height, width: Math.abs(pos[0] - relPos), fill: 'rgba(0, 0, 0, 0.125)' }) }))) })), _jsxs("g", __assign({ transform: "translate(".concat(margin, ", ").concat(margin, ")") }, { children: [_jsx("g", __assign({ className: "lines fill-transparent" }, { children: series.map(function (s, i) { return (_jsx("g", __assign({ className: "line" }, { children: _jsx(MetricsSeries, { data: s, line: l, color: color(i.toString()), strokeWidth: hovering && highlighted != null && i === highlighted.seriesIndex
                                                ? lineStrokeHover
                                                : lineStroke, xScale: xScale, yScale: yScale }) }), i)); }) })), hovering && highlighted != null && (_jsx("g", __assign({ className: "circle-group", ref: metricPointRef, style: { fill: color(highlighted.seriesIndex.toString()) } }, { children: _jsx(MetricsCircle, { cx: highlighted.x, cy: highlighted.y }) }))), selected != null && (_jsx("g", __assign({ className: "circle-group", style: (selected === null || selected === void 0 ? void 0 : selected.seriesIndex) != null
                                        ? { fill: color(selected.seriesIndex.toString()) }
                                        : {} }, { children: _jsx(MetricsCircle, { cx: selected.x, cy: selected.y, radius: 5 }) }))), _jsx("g", __assign({ className: "x axis", fill: "none", fontSize: "10", textAnchor: "middle", transform: "translate(0,".concat(height - margin, ")") }, { children: xScale.ticks(5).map(function (d, i) { return (_jsxs("g", __assign({ className: "tick", 
                                        /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                                        transform: "translate(".concat(xScale(d), ", 0)") }, { children: [_jsx("line", { y2: 6, stroke: "currentColor" }), _jsx("text", __assign({ fill: "currentColor", dy: ".71em", y: 9 }, { children: formatDate(d, formatForTimespan(from, to)) }))] }), i)); }) })), _jsx("g", __assign({ className: "y axis", textAnchor: "end", fontSize: "10", fill: "none" }, { children: yScale.ticks(3).map(function (d, i) { return (_jsxs("g", __assign({ className: "tick", 
                                        /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
                                        transform: "translate(0, ".concat(yScale(d), ")") }, { children: [_jsx("line", { stroke: "currentColor", x2: -6 }), _jsx("text", __assign({ fill: "currentColor", x: -9, dy: '0.32em' }, { children: valueFormatter(d, sampleUnit, 1) }))] }), i)); }) }))] }))] })) }))] }));
};
