import { ScrapeService } from "./scrape";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * ScrapeService maintains the set of scrape targets
 *
 * @generated from protobuf service parca.scrape.v1alpha1.ScrapeService
 */
export class ScrapeServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = ScrapeService.typeName;
        this.methods = ScrapeService.methods;
        this.options = ScrapeService.options;
    }
    /**
     * Targets returns the set of scrape targets that are configured
     *
     * @generated from protobuf rpc: Targets(parca.scrape.v1alpha1.TargetsRequest) returns (parca.scrape.v1alpha1.TargetsResponse);
     */
    targets(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
