// @generated by protobuf-ts 2.8.2 with parameter long_type_string,generate_dependencies
// @generated from protobuf file "parca/query/v1alpha1/query.proto" (package "parca.query.v1alpha1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Line } from "../../metastore/v1alpha1/metastore";
import { Function } from "../../metastore/v1alpha1/metastore";
import { Mapping } from "../../metastore/v1alpha1/metastore";
import { Location } from "../../metastore/v1alpha1/metastore";
import { LabelSet } from "../../profilestore/v1alpha1/profilestore";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * Mode specifies the type of diff
 *
 * @generated from protobuf enum parca.query.v1alpha1.ProfileDiffSelection.Mode
 */
export var ProfileDiffSelection_Mode;
(function (ProfileDiffSelection_Mode) {
    /**
     * MODE_SINGLE_UNSPECIFIED default unspecified
     *
     * @generated from protobuf enum value: MODE_SINGLE_UNSPECIFIED = 0;
     */
    ProfileDiffSelection_Mode[ProfileDiffSelection_Mode["SINGLE_UNSPECIFIED"] = 0] = "SINGLE_UNSPECIFIED";
    /**
     * MODE_MERGE merge profile
     *
     * @generated from protobuf enum value: MODE_MERGE = 1;
     */
    ProfileDiffSelection_Mode[ProfileDiffSelection_Mode["MERGE"] = 1] = "MERGE";
})(ProfileDiffSelection_Mode || (ProfileDiffSelection_Mode = {}));
/**
 * Mode is the type of query request
 *
 * @generated from protobuf enum parca.query.v1alpha1.QueryRequest.Mode
 */
export var QueryRequest_Mode;
(function (QueryRequest_Mode) {
    /**
     * MODE_SINGLE_UNSPECIFIED query unspecified
     *
     * @generated from protobuf enum value: MODE_SINGLE_UNSPECIFIED = 0;
     */
    QueryRequest_Mode[QueryRequest_Mode["SINGLE_UNSPECIFIED"] = 0] = "SINGLE_UNSPECIFIED";
    /**
     * MODE_DIFF is a diff query
     *
     * @generated from protobuf enum value: MODE_DIFF = 1;
     */
    QueryRequest_Mode[QueryRequest_Mode["DIFF"] = 1] = "DIFF";
    /**
     * MODE_MERGE is a merge query
     *
     * @generated from protobuf enum value: MODE_MERGE = 2;
     */
    QueryRequest_Mode[QueryRequest_Mode["MERGE"] = 2] = "MERGE";
})(QueryRequest_Mode || (QueryRequest_Mode = {}));
/**
 * ReportType is the type of report to return
 *
 * @generated from protobuf enum parca.query.v1alpha1.QueryRequest.ReportType
 */
export var QueryRequest_ReportType;
(function (QueryRequest_ReportType) {
    /**
     * REPORT_TYPE_FLAMEGRAPH_UNSPECIFIED unspecified
     *
     * @deprecated
     * @generated from protobuf enum value: REPORT_TYPE_FLAMEGRAPH_UNSPECIFIED = 0 [deprecated = true];
     */
    QueryRequest_ReportType[QueryRequest_ReportType["FLAMEGRAPH_UNSPECIFIED"] = 0] = "FLAMEGRAPH_UNSPECIFIED";
    /**
     * REPORT_TYPE_PPROF unspecified
     *
     * @generated from protobuf enum value: REPORT_TYPE_PPROF = 1;
     */
    QueryRequest_ReportType[QueryRequest_ReportType["PPROF"] = 1] = "PPROF";
    /**
     * REPORT_TYPE_TOP unspecified
     *
     * @generated from protobuf enum value: REPORT_TYPE_TOP = 2;
     */
    QueryRequest_ReportType[QueryRequest_ReportType["TOP"] = 2] = "TOP";
    /**
     * REPORT_TYPE_CALLGRAPH unspecified
     *
     * @generated from protobuf enum value: REPORT_TYPE_CALLGRAPH = 3;
     */
    QueryRequest_ReportType[QueryRequest_ReportType["CALLGRAPH"] = 3] = "CALLGRAPH";
    /**
     * REPORT_TYPE_FLAMEGRAPH_TABLE unspecified
     *
     * @generated from protobuf enum value: REPORT_TYPE_FLAMEGRAPH_TABLE = 4;
     */
    QueryRequest_ReportType[QueryRequest_ReportType["FLAMEGRAPH_TABLE"] = 4] = "FLAMEGRAPH_TABLE";
})(QueryRequest_ReportType || (QueryRequest_ReportType = {}));
// @generated message type with reflection information, may provide speed optimized methods
class ProfileTypesRequest$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.ProfileTypesRequest", []);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        return target !== null && target !== void 0 ? target : this.create();
    }
    internalBinaryWrite(message, writer, options) {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.ProfileTypesRequest
 */
export const ProfileTypesRequest = new ProfileTypesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProfileTypesResponse$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.ProfileTypesResponse", [
            { no: 1, name: "types", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProfileType }
        ]);
    }
    create(value) {
        const message = { types: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.query.v1alpha1.ProfileType types */ 1:
                    message.types.push(ProfileType.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.query.v1alpha1.ProfileType types = 1; */
        for (let i = 0; i < message.types.length; i++)
            ProfileType.internalBinaryWrite(message.types[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.ProfileTypesResponse
 */
export const ProfileTypesResponse = new ProfileTypesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProfileType$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.ProfileType", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "sample_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "sample_unit", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "period_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "period_unit", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "delta", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { name: "", sampleType: "", sampleUnit: "", periodType: "", periodUnit: "", delta: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string sample_type */ 2:
                    message.sampleType = reader.string();
                    break;
                case /* string sample_unit */ 3:
                    message.sampleUnit = reader.string();
                    break;
                case /* string period_type */ 4:
                    message.periodType = reader.string();
                    break;
                case /* string period_unit */ 5:
                    message.periodUnit = reader.string();
                    break;
                case /* bool delta */ 6:
                    message.delta = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string sample_type = 2; */
        if (message.sampleType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sampleType);
        /* string sample_unit = 3; */
        if (message.sampleUnit !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.sampleUnit);
        /* string period_type = 4; */
        if (message.periodType !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.periodType);
        /* string period_unit = 5; */
        if (message.periodUnit !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.periodUnit);
        /* bool delta = 6; */
        if (message.delta !== false)
            writer.tag(6, WireType.Varint).bool(message.delta);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.ProfileType
 */
export const ProfileType = new ProfileType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryRangeRequest$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.QueryRangeRequest", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "start", kind: "message", T: () => Timestamp },
            { no: 3, name: "end", kind: "message", T: () => Timestamp },
            { no: 4, name: "limit", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value) {
        const message = { query: "", limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* google.protobuf.Timestamp start */ 2:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* google.protobuf.Timestamp end */ 3:
                    message.end = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.end);
                    break;
                case /* uint32 limit */ 4:
                    message.limit = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* google.protobuf.Timestamp start = 2; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp end = 3; */
        if (message.end)
            Timestamp.internalBinaryWrite(message.end, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* uint32 limit = 4; */
        if (message.limit !== 0)
            writer.tag(4, WireType.Varint).uint32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.QueryRangeRequest
 */
export const QueryRangeRequest = new QueryRangeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryRangeResponse$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.QueryRangeResponse", [
            { no: 1, name: "series", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MetricsSeries }
        ]);
    }
    create(value) {
        const message = { series: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.query.v1alpha1.MetricsSeries series */ 1:
                    message.series.push(MetricsSeries.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.query.v1alpha1.MetricsSeries series = 1; */
        for (let i = 0; i < message.series.length; i++)
            MetricsSeries.internalBinaryWrite(message.series[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.QueryRangeResponse
 */
export const QueryRangeResponse = new QueryRangeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MetricsSeries$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.MetricsSeries", [
            { no: 1, name: "labelset", kind: "message", T: () => LabelSet },
            { no: 2, name: "samples", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MetricsSample },
            { no: 3, name: "period_type", kind: "message", T: () => ValueType },
            { no: 4, name: "sample_type", kind: "message", T: () => ValueType }
        ]);
    }
    create(value) {
        const message = { samples: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* parca.profilestore.v1alpha1.LabelSet labelset */ 1:
                    message.labelset = LabelSet.internalBinaryRead(reader, reader.uint32(), options, message.labelset);
                    break;
                case /* repeated parca.query.v1alpha1.MetricsSample samples */ 2:
                    message.samples.push(MetricsSample.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* parca.query.v1alpha1.ValueType period_type */ 3:
                    message.periodType = ValueType.internalBinaryRead(reader, reader.uint32(), options, message.periodType);
                    break;
                case /* parca.query.v1alpha1.ValueType sample_type */ 4:
                    message.sampleType = ValueType.internalBinaryRead(reader, reader.uint32(), options, message.sampleType);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* parca.profilestore.v1alpha1.LabelSet labelset = 1; */
        if (message.labelset)
            LabelSet.internalBinaryWrite(message.labelset, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated parca.query.v1alpha1.MetricsSample samples = 2; */
        for (let i = 0; i < message.samples.length; i++)
            MetricsSample.internalBinaryWrite(message.samples[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* parca.query.v1alpha1.ValueType period_type = 3; */
        if (message.periodType)
            ValueType.internalBinaryWrite(message.periodType, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* parca.query.v1alpha1.ValueType sample_type = 4; */
        if (message.sampleType)
            ValueType.internalBinaryWrite(message.sampleType, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.MetricsSeries
 */
export const MetricsSeries = new MetricsSeries$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MetricsSample$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.MetricsSample", [
            { no: 1, name: "timestamp", kind: "message", T: () => Timestamp },
            { no: 2, name: "value", kind: "scalar", T: 3 /*ScalarType.INT64*/ }
        ]);
    }
    create(value) {
        const message = { value: "0" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp timestamp */ 1:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                case /* int64 value */ 2:
                    message.value = reader.int64().toString();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* google.protobuf.Timestamp timestamp = 1; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 value = 2; */
        if (message.value !== "0")
            writer.tag(2, WireType.Varint).int64(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.MetricsSample
 */
export const MetricsSample = new MetricsSample$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MergeProfile$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.MergeProfile", [
            { no: 1, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "start", kind: "message", T: () => Timestamp },
            { no: 3, name: "end", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value) {
        const message = { query: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string query */ 1:
                    message.query = reader.string();
                    break;
                case /* google.protobuf.Timestamp start */ 2:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* google.protobuf.Timestamp end */ 3:
                    message.end = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.end);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string query = 1; */
        if (message.query !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.query);
        /* google.protobuf.Timestamp start = 2; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp end = 3; */
        if (message.end)
            Timestamp.internalBinaryWrite(message.end, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.MergeProfile
 */
export const MergeProfile = new MergeProfile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SingleProfile$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.SingleProfile", [
            { no: 1, name: "time", kind: "message", T: () => Timestamp },
            { no: 2, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { query: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp time */ 1:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                case /* string query */ 2:
                    message.query = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* google.protobuf.Timestamp time = 1; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string query = 2; */
        if (message.query !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.query);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.SingleProfile
 */
export const SingleProfile = new SingleProfile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DiffProfile$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.DiffProfile", [
            { no: 1, name: "a", kind: "message", T: () => ProfileDiffSelection },
            { no: 2, name: "b", kind: "message", T: () => ProfileDiffSelection }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* parca.query.v1alpha1.ProfileDiffSelection a */ 1:
                    message.a = ProfileDiffSelection.internalBinaryRead(reader, reader.uint32(), options, message.a);
                    break;
                case /* parca.query.v1alpha1.ProfileDiffSelection b */ 2:
                    message.b = ProfileDiffSelection.internalBinaryRead(reader, reader.uint32(), options, message.b);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* parca.query.v1alpha1.ProfileDiffSelection a = 1; */
        if (message.a)
            ProfileDiffSelection.internalBinaryWrite(message.a, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* parca.query.v1alpha1.ProfileDiffSelection b = 2; */
        if (message.b)
            ProfileDiffSelection.internalBinaryWrite(message.b, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.DiffProfile
 */
export const DiffProfile = new DiffProfile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProfileDiffSelection$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.ProfileDiffSelection", [
            { no: 1, name: "mode", kind: "enum", T: () => ["parca.query.v1alpha1.ProfileDiffSelection.Mode", ProfileDiffSelection_Mode, "MODE_"] },
            { no: 2, name: "merge", kind: "message", oneof: "options", T: () => MergeProfile },
            { no: 3, name: "single", kind: "message", oneof: "options", T: () => SingleProfile }
        ]);
    }
    create(value) {
        const message = { mode: 0, options: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* parca.query.v1alpha1.ProfileDiffSelection.Mode mode */ 1:
                    message.mode = reader.int32();
                    break;
                case /* parca.query.v1alpha1.MergeProfile merge */ 2:
                    message.options = {
                        oneofKind: "merge",
                        merge: MergeProfile.internalBinaryRead(reader, reader.uint32(), options, message.options.merge)
                    };
                    break;
                case /* parca.query.v1alpha1.SingleProfile single */ 3:
                    message.options = {
                        oneofKind: "single",
                        single: SingleProfile.internalBinaryRead(reader, reader.uint32(), options, message.options.single)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* parca.query.v1alpha1.ProfileDiffSelection.Mode mode = 1; */
        if (message.mode !== 0)
            writer.tag(1, WireType.Varint).int32(message.mode);
        /* parca.query.v1alpha1.MergeProfile merge = 2; */
        if (message.options.oneofKind === "merge")
            MergeProfile.internalBinaryWrite(message.options.merge, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* parca.query.v1alpha1.SingleProfile single = 3; */
        if (message.options.oneofKind === "single")
            SingleProfile.internalBinaryWrite(message.options.single, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.ProfileDiffSelection
 */
export const ProfileDiffSelection = new ProfileDiffSelection$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryRequest$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.QueryRequest", [
            { no: 1, name: "mode", kind: "enum", T: () => ["parca.query.v1alpha1.QueryRequest.Mode", QueryRequest_Mode, "MODE_"] },
            { no: 2, name: "diff", kind: "message", oneof: "options", T: () => DiffProfile },
            { no: 3, name: "merge", kind: "message", oneof: "options", T: () => MergeProfile },
            { no: 4, name: "single", kind: "message", oneof: "options", T: () => SingleProfile },
            { no: 5, name: "report_type", kind: "enum", T: () => ["parca.query.v1alpha1.QueryRequest.ReportType", QueryRequest_ReportType, "REPORT_TYPE_"] },
            { no: 6, name: "filter_query", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { mode: 0, options: { oneofKind: undefined }, reportType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* parca.query.v1alpha1.QueryRequest.Mode mode */ 1:
                    message.mode = reader.int32();
                    break;
                case /* parca.query.v1alpha1.DiffProfile diff */ 2:
                    message.options = {
                        oneofKind: "diff",
                        diff: DiffProfile.internalBinaryRead(reader, reader.uint32(), options, message.options.diff)
                    };
                    break;
                case /* parca.query.v1alpha1.MergeProfile merge */ 3:
                    message.options = {
                        oneofKind: "merge",
                        merge: MergeProfile.internalBinaryRead(reader, reader.uint32(), options, message.options.merge)
                    };
                    break;
                case /* parca.query.v1alpha1.SingleProfile single */ 4:
                    message.options = {
                        oneofKind: "single",
                        single: SingleProfile.internalBinaryRead(reader, reader.uint32(), options, message.options.single)
                    };
                    break;
                case /* parca.query.v1alpha1.QueryRequest.ReportType report_type */ 5:
                    message.reportType = reader.int32();
                    break;
                case /* optional string filter_query */ 6:
                    message.filterQuery = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* parca.query.v1alpha1.QueryRequest.Mode mode = 1; */
        if (message.mode !== 0)
            writer.tag(1, WireType.Varint).int32(message.mode);
        /* parca.query.v1alpha1.DiffProfile diff = 2; */
        if (message.options.oneofKind === "diff")
            DiffProfile.internalBinaryWrite(message.options.diff, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* parca.query.v1alpha1.MergeProfile merge = 3; */
        if (message.options.oneofKind === "merge")
            MergeProfile.internalBinaryWrite(message.options.merge, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* parca.query.v1alpha1.SingleProfile single = 4; */
        if (message.options.oneofKind === "single")
            SingleProfile.internalBinaryWrite(message.options.single, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* parca.query.v1alpha1.QueryRequest.ReportType report_type = 5; */
        if (message.reportType !== 0)
            writer.tag(5, WireType.Varint).int32(message.reportType);
        /* optional string filter_query = 6; */
        if (message.filterQuery !== undefined)
            writer.tag(6, WireType.LengthDelimited).string(message.filterQuery);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.QueryRequest
 */
export const QueryRequest = new QueryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Top$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.Top", [
            { no: 1, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TopNode },
            { no: 2, name: "reported", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "unit", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { list: [], reported: 0, total: 0, unit: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.query.v1alpha1.TopNode list */ 1:
                    message.list.push(TopNode.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 reported */ 2:
                    message.reported = reader.int32();
                    break;
                case /* int32 total */ 3:
                    message.total = reader.int32();
                    break;
                case /* string unit */ 4:
                    message.unit = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.query.v1alpha1.TopNode list = 1; */
        for (let i = 0; i < message.list.length; i++)
            TopNode.internalBinaryWrite(message.list[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 reported = 2; */
        if (message.reported !== 0)
            writer.tag(2, WireType.Varint).int32(message.reported);
        /* int32 total = 3; */
        if (message.total !== 0)
            writer.tag(3, WireType.Varint).int32(message.total);
        /* string unit = 4; */
        if (message.unit !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.unit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.Top
 */
export const Top = new Top$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TopNode$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.TopNode", [
            { no: 1, name: "meta", kind: "message", T: () => TopNodeMeta },
            { no: 2, name: "cumulative", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 3, name: "flat", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 4, name: "diff", kind: "scalar", T: 3 /*ScalarType.INT64*/ }
        ]);
    }
    create(value) {
        const message = { cumulative: "0", flat: "0", diff: "0" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* parca.query.v1alpha1.TopNodeMeta meta */ 1:
                    message.meta = TopNodeMeta.internalBinaryRead(reader, reader.uint32(), options, message.meta);
                    break;
                case /* int64 cumulative */ 2:
                    message.cumulative = reader.int64().toString();
                    break;
                case /* int64 flat */ 3:
                    message.flat = reader.int64().toString();
                    break;
                case /* int64 diff */ 4:
                    message.diff = reader.int64().toString();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* parca.query.v1alpha1.TopNodeMeta meta = 1; */
        if (message.meta)
            TopNodeMeta.internalBinaryWrite(message.meta, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 cumulative = 2; */
        if (message.cumulative !== "0")
            writer.tag(2, WireType.Varint).int64(message.cumulative);
        /* int64 flat = 3; */
        if (message.flat !== "0")
            writer.tag(3, WireType.Varint).int64(message.flat);
        /* int64 diff = 4; */
        if (message.diff !== "0")
            writer.tag(4, WireType.Varint).int64(message.diff);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.TopNode
 */
export const TopNode = new TopNode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TopNodeMeta$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.TopNodeMeta", [
            { no: 1, name: "location", kind: "message", T: () => Location },
            { no: 2, name: "mapping", kind: "message", T: () => Mapping },
            { no: 3, name: "function", kind: "message", T: () => Function },
            { no: 4, name: "line", kind: "message", T: () => Line }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* parca.metastore.v1alpha1.Location location */ 1:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* parca.metastore.v1alpha1.Mapping mapping */ 2:
                    message.mapping = Mapping.internalBinaryRead(reader, reader.uint32(), options, message.mapping);
                    break;
                case /* parca.metastore.v1alpha1.Function function */ 3:
                    message.function = Function.internalBinaryRead(reader, reader.uint32(), options, message.function);
                    break;
                case /* parca.metastore.v1alpha1.Line line */ 4:
                    message.line = Line.internalBinaryRead(reader, reader.uint32(), options, message.line);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* parca.metastore.v1alpha1.Location location = 1; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* parca.metastore.v1alpha1.Mapping mapping = 2; */
        if (message.mapping)
            Mapping.internalBinaryWrite(message.mapping, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* parca.metastore.v1alpha1.Function function = 3; */
        if (message.function)
            Function.internalBinaryWrite(message.function, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* parca.metastore.v1alpha1.Line line = 4; */
        if (message.line)
            Line.internalBinaryWrite(message.line, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.TopNodeMeta
 */
export const TopNodeMeta = new TopNodeMeta$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Flamegraph$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.Flamegraph", [
            { no: 1, name: "root", kind: "message", T: () => FlamegraphRootNode },
            { no: 2, name: "total", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 3, name: "unit", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "height", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "string_table", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "locations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Location },
            { no: 7, name: "mapping", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Mapping },
            { no: 8, name: "function", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Function }
        ]);
    }
    create(value) {
        const message = { total: "0", unit: "", height: 0, stringTable: [], locations: [], mapping: [], function: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* parca.query.v1alpha1.FlamegraphRootNode root */ 1:
                    message.root = FlamegraphRootNode.internalBinaryRead(reader, reader.uint32(), options, message.root);
                    break;
                case /* int64 total */ 2:
                    message.total = reader.int64().toString();
                    break;
                case /* string unit */ 3:
                    message.unit = reader.string();
                    break;
                case /* int32 height */ 4:
                    message.height = reader.int32();
                    break;
                case /* repeated string string_table */ 5:
                    message.stringTable.push(reader.string());
                    break;
                case /* repeated parca.metastore.v1alpha1.Location locations */ 6:
                    message.locations.push(Location.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated parca.metastore.v1alpha1.Mapping mapping */ 7:
                    message.mapping.push(Mapping.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated parca.metastore.v1alpha1.Function function */ 8:
                    message.function.push(Function.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* parca.query.v1alpha1.FlamegraphRootNode root = 1; */
        if (message.root)
            FlamegraphRootNode.internalBinaryWrite(message.root, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 total = 2; */
        if (message.total !== "0")
            writer.tag(2, WireType.Varint).int64(message.total);
        /* string unit = 3; */
        if (message.unit !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.unit);
        /* int32 height = 4; */
        if (message.height !== 0)
            writer.tag(4, WireType.Varint).int32(message.height);
        /* repeated string string_table = 5; */
        for (let i = 0; i < message.stringTable.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.stringTable[i]);
        /* repeated parca.metastore.v1alpha1.Location locations = 6; */
        for (let i = 0; i < message.locations.length; i++)
            Location.internalBinaryWrite(message.locations[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated parca.metastore.v1alpha1.Mapping mapping = 7; */
        for (let i = 0; i < message.mapping.length; i++)
            Mapping.internalBinaryWrite(message.mapping[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated parca.metastore.v1alpha1.Function function = 8; */
        for (let i = 0; i < message.function.length; i++)
            Function.internalBinaryWrite(message.function[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.Flamegraph
 */
export const Flamegraph = new Flamegraph$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlamegraphRootNode$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.FlamegraphRootNode", [
            { no: 1, name: "cumulative", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 2, name: "diff", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 3, name: "children", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FlamegraphNode }
        ]);
    }
    create(value) {
        const message = { cumulative: "0", diff: "0", children: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 cumulative */ 1:
                    message.cumulative = reader.int64().toString();
                    break;
                case /* int64 diff */ 2:
                    message.diff = reader.int64().toString();
                    break;
                case /* repeated parca.query.v1alpha1.FlamegraphNode children */ 3:
                    message.children.push(FlamegraphNode.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int64 cumulative = 1; */
        if (message.cumulative !== "0")
            writer.tag(1, WireType.Varint).int64(message.cumulative);
        /* int64 diff = 2; */
        if (message.diff !== "0")
            writer.tag(2, WireType.Varint).int64(message.diff);
        /* repeated parca.query.v1alpha1.FlamegraphNode children = 3; */
        for (let i = 0; i < message.children.length; i++)
            FlamegraphNode.internalBinaryWrite(message.children[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.FlamegraphRootNode
 */
export const FlamegraphRootNode = new FlamegraphRootNode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlamegraphNode$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.FlamegraphNode", [
            { no: 1, name: "meta", kind: "message", T: () => FlamegraphNodeMeta },
            { no: 2, name: "cumulative", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 3, name: "diff", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 4, name: "children", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FlamegraphNode }
        ]);
    }
    create(value) {
        const message = { cumulative: "0", diff: "0", children: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* parca.query.v1alpha1.FlamegraphNodeMeta meta */ 1:
                    message.meta = FlamegraphNodeMeta.internalBinaryRead(reader, reader.uint32(), options, message.meta);
                    break;
                case /* int64 cumulative */ 2:
                    message.cumulative = reader.int64().toString();
                    break;
                case /* int64 diff */ 3:
                    message.diff = reader.int64().toString();
                    break;
                case /* repeated parca.query.v1alpha1.FlamegraphNode children */ 4:
                    message.children.push(FlamegraphNode.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* parca.query.v1alpha1.FlamegraphNodeMeta meta = 1; */
        if (message.meta)
            FlamegraphNodeMeta.internalBinaryWrite(message.meta, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 cumulative = 2; */
        if (message.cumulative !== "0")
            writer.tag(2, WireType.Varint).int64(message.cumulative);
        /* int64 diff = 3; */
        if (message.diff !== "0")
            writer.tag(3, WireType.Varint).int64(message.diff);
        /* repeated parca.query.v1alpha1.FlamegraphNode children = 4; */
        for (let i = 0; i < message.children.length; i++)
            FlamegraphNode.internalBinaryWrite(message.children[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.FlamegraphNode
 */
export const FlamegraphNode = new FlamegraphNode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlamegraphNodeMeta$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.FlamegraphNodeMeta", [
            { no: 1, name: "location", kind: "message", T: () => Location },
            { no: 2, name: "mapping", kind: "message", T: () => Mapping },
            { no: 3, name: "function", kind: "message", T: () => Function },
            { no: 4, name: "line", kind: "message", T: () => Line },
            { no: 5, name: "location_index", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "line_index", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value) {
        const message = { locationIndex: 0, lineIndex: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* parca.metastore.v1alpha1.Location location */ 1:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* parca.metastore.v1alpha1.Mapping mapping */ 2:
                    message.mapping = Mapping.internalBinaryRead(reader, reader.uint32(), options, message.mapping);
                    break;
                case /* parca.metastore.v1alpha1.Function function */ 3:
                    message.function = Function.internalBinaryRead(reader, reader.uint32(), options, message.function);
                    break;
                case /* parca.metastore.v1alpha1.Line line */ 4:
                    message.line = Line.internalBinaryRead(reader, reader.uint32(), options, message.line);
                    break;
                case /* uint32 location_index */ 5:
                    message.locationIndex = reader.uint32();
                    break;
                case /* uint32 line_index */ 6:
                    message.lineIndex = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* parca.metastore.v1alpha1.Location location = 1; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* parca.metastore.v1alpha1.Mapping mapping = 2; */
        if (message.mapping)
            Mapping.internalBinaryWrite(message.mapping, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* parca.metastore.v1alpha1.Function function = 3; */
        if (message.function)
            Function.internalBinaryWrite(message.function, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* parca.metastore.v1alpha1.Line line = 4; */
        if (message.line)
            Line.internalBinaryWrite(message.line, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* uint32 location_index = 5; */
        if (message.locationIndex !== 0)
            writer.tag(5, WireType.Varint).uint32(message.locationIndex);
        /* uint32 line_index = 6; */
        if (message.lineIndex !== 0)
            writer.tag(6, WireType.Varint).uint32(message.lineIndex);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.FlamegraphNodeMeta
 */
export const FlamegraphNodeMeta = new FlamegraphNodeMeta$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CallgraphNode$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.CallgraphNode", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "meta", kind: "message", T: () => CallgraphNodeMeta },
            { no: 3, name: "cumulative", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 4, name: "flat", kind: "scalar", T: 3 /*ScalarType.INT64*/ }
        ]);
    }
    create(value) {
        const message = { id: "", cumulative: "0", flat: "0" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* parca.query.v1alpha1.CallgraphNodeMeta meta */ 2:
                    message.meta = CallgraphNodeMeta.internalBinaryRead(reader, reader.uint32(), options, message.meta);
                    break;
                case /* int64 cumulative */ 3:
                    message.cumulative = reader.int64().toString();
                    break;
                case /* int64 flat */ 4:
                    message.flat = reader.int64().toString();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* parca.query.v1alpha1.CallgraphNodeMeta meta = 2; */
        if (message.meta)
            CallgraphNodeMeta.internalBinaryWrite(message.meta, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int64 cumulative = 3; */
        if (message.cumulative !== "0")
            writer.tag(3, WireType.Varint).int64(message.cumulative);
        /* int64 flat = 4; */
        if (message.flat !== "0")
            writer.tag(4, WireType.Varint).int64(message.flat);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.CallgraphNode
 */
export const CallgraphNode = new CallgraphNode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CallgraphNodeMeta$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.CallgraphNodeMeta", [
            { no: 1, name: "location", kind: "message", T: () => Location },
            { no: 2, name: "mapping", kind: "message", T: () => Mapping },
            { no: 3, name: "function", kind: "message", T: () => Function },
            { no: 4, name: "line", kind: "message", T: () => Line }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* parca.metastore.v1alpha1.Location location */ 1:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* parca.metastore.v1alpha1.Mapping mapping */ 2:
                    message.mapping = Mapping.internalBinaryRead(reader, reader.uint32(), options, message.mapping);
                    break;
                case /* parca.metastore.v1alpha1.Function function */ 3:
                    message.function = Function.internalBinaryRead(reader, reader.uint32(), options, message.function);
                    break;
                case /* parca.metastore.v1alpha1.Line line */ 4:
                    message.line = Line.internalBinaryRead(reader, reader.uint32(), options, message.line);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* parca.metastore.v1alpha1.Location location = 1; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* parca.metastore.v1alpha1.Mapping mapping = 2; */
        if (message.mapping)
            Mapping.internalBinaryWrite(message.mapping, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* parca.metastore.v1alpha1.Function function = 3; */
        if (message.function)
            Function.internalBinaryWrite(message.function, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* parca.metastore.v1alpha1.Line line = 4; */
        if (message.line)
            Line.internalBinaryWrite(message.line, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.CallgraphNodeMeta
 */
export const CallgraphNodeMeta = new CallgraphNodeMeta$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CallgraphEdge$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.CallgraphEdge", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "source", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "target", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "cumulative", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 5, name: "is_collapsed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: "", source: "", target: "", cumulative: "0", isCollapsed: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string source */ 2:
                    message.source = reader.string();
                    break;
                case /* string target */ 3:
                    message.target = reader.string();
                    break;
                case /* int64 cumulative */ 4:
                    message.cumulative = reader.int64().toString();
                    break;
                case /* bool is_collapsed */ 5:
                    message.isCollapsed = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string source = 2; */
        if (message.source !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.source);
        /* string target = 3; */
        if (message.target !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.target);
        /* int64 cumulative = 4; */
        if (message.cumulative !== "0")
            writer.tag(4, WireType.Varint).int64(message.cumulative);
        /* bool is_collapsed = 5; */
        if (message.isCollapsed !== false)
            writer.tag(5, WireType.Varint).bool(message.isCollapsed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.CallgraphEdge
 */
export const CallgraphEdge = new CallgraphEdge$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Callgraph$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.Callgraph", [
            { no: 1, name: "nodes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CallgraphNode },
            { no: 2, name: "edges", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CallgraphEdge },
            { no: 3, name: "cumulative", kind: "scalar", T: 3 /*ScalarType.INT64*/ }
        ]);
    }
    create(value) {
        const message = { nodes: [], edges: [], cumulative: "0" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.query.v1alpha1.CallgraphNode nodes */ 1:
                    message.nodes.push(CallgraphNode.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated parca.query.v1alpha1.CallgraphEdge edges */ 2:
                    message.edges.push(CallgraphEdge.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 cumulative */ 3:
                    message.cumulative = reader.int64().toString();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.query.v1alpha1.CallgraphNode nodes = 1; */
        for (let i = 0; i < message.nodes.length; i++)
            CallgraphNode.internalBinaryWrite(message.nodes[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated parca.query.v1alpha1.CallgraphEdge edges = 2; */
        for (let i = 0; i < message.edges.length; i++)
            CallgraphEdge.internalBinaryWrite(message.edges[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int64 cumulative = 3; */
        if (message.cumulative !== "0")
            writer.tag(3, WireType.Varint).int64(message.cumulative);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.Callgraph
 */
export const Callgraph = new Callgraph$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QueryResponse$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.QueryResponse", [
            { no: 5, name: "flamegraph", kind: "message", oneof: "report", T: () => Flamegraph },
            { no: 6, name: "pprof", kind: "scalar", oneof: "report", T: 12 /*ScalarType.BYTES*/ },
            { no: 7, name: "top", kind: "message", oneof: "report", T: () => Top },
            { no: 8, name: "callgraph", kind: "message", oneof: "report", T: () => Callgraph }
        ]);
    }
    create(value) {
        const message = { report: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* parca.query.v1alpha1.Flamegraph flamegraph */ 5:
                    message.report = {
                        oneofKind: "flamegraph",
                        flamegraph: Flamegraph.internalBinaryRead(reader, reader.uint32(), options, message.report.flamegraph)
                    };
                    break;
                case /* bytes pprof */ 6:
                    message.report = {
                        oneofKind: "pprof",
                        pprof: reader.bytes()
                    };
                    break;
                case /* parca.query.v1alpha1.Top top */ 7:
                    message.report = {
                        oneofKind: "top",
                        top: Top.internalBinaryRead(reader, reader.uint32(), options, message.report.top)
                    };
                    break;
                case /* parca.query.v1alpha1.Callgraph callgraph */ 8:
                    message.report = {
                        oneofKind: "callgraph",
                        callgraph: Callgraph.internalBinaryRead(reader, reader.uint32(), options, message.report.callgraph)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* parca.query.v1alpha1.Flamegraph flamegraph = 5; */
        if (message.report.oneofKind === "flamegraph")
            Flamegraph.internalBinaryWrite(message.report.flamegraph, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bytes pprof = 6; */
        if (message.report.oneofKind === "pprof")
            writer.tag(6, WireType.LengthDelimited).bytes(message.report.pprof);
        /* parca.query.v1alpha1.Top top = 7; */
        if (message.report.oneofKind === "top")
            Top.internalBinaryWrite(message.report.top, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* parca.query.v1alpha1.Callgraph callgraph = 8; */
        if (message.report.oneofKind === "callgraph")
            Callgraph.internalBinaryWrite(message.report.callgraph, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.QueryResponse
 */
export const QueryResponse = new QueryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SeriesRequest$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.SeriesRequest", [
            { no: 1, name: "match", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "start", kind: "message", T: () => Timestamp },
            { no: 3, name: "end", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value) {
        const message = { match: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string match */ 1:
                    message.match.push(reader.string());
                    break;
                case /* google.protobuf.Timestamp start */ 2:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* google.protobuf.Timestamp end */ 3:
                    message.end = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.end);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string match = 1; */
        for (let i = 0; i < message.match.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.match[i]);
        /* google.protobuf.Timestamp start = 2; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp end = 3; */
        if (message.end)
            Timestamp.internalBinaryWrite(message.end, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.SeriesRequest
 */
export const SeriesRequest = new SeriesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SeriesResponse$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.SeriesResponse", []);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        return target !== null && target !== void 0 ? target : this.create();
    }
    internalBinaryWrite(message, writer, options) {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.SeriesResponse
 */
export const SeriesResponse = new SeriesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LabelsRequest$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.LabelsRequest", [
            { no: 1, name: "match", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "start", kind: "message", T: () => Timestamp },
            { no: 3, name: "end", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value) {
        const message = { match: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string match */ 1:
                    message.match.push(reader.string());
                    break;
                case /* google.protobuf.Timestamp start */ 2:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* google.protobuf.Timestamp end */ 3:
                    message.end = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.end);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string match = 1; */
        for (let i = 0; i < message.match.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.match[i]);
        /* google.protobuf.Timestamp start = 2; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp end = 3; */
        if (message.end)
            Timestamp.internalBinaryWrite(message.end, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.LabelsRequest
 */
export const LabelsRequest = new LabelsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LabelsResponse$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.LabelsResponse", [
            { no: 1, name: "label_names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "warnings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { labelNames: [], warnings: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string label_names */ 1:
                    message.labelNames.push(reader.string());
                    break;
                case /* repeated string warnings */ 2:
                    message.warnings.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string label_names = 1; */
        for (let i = 0; i < message.labelNames.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.labelNames[i]);
        /* repeated string warnings = 2; */
        for (let i = 0; i < message.warnings.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.warnings[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.LabelsResponse
 */
export const LabelsResponse = new LabelsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValuesRequest$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.ValuesRequest", [
            { no: 1, name: "label_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "match", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "start", kind: "message", T: () => Timestamp },
            { no: 4, name: "end", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value) {
        const message = { labelName: "", match: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label_name */ 1:
                    message.labelName = reader.string();
                    break;
                case /* repeated string match */ 2:
                    message.match.push(reader.string());
                    break;
                case /* google.protobuf.Timestamp start */ 3:
                    message.start = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.start);
                    break;
                case /* google.protobuf.Timestamp end */ 4:
                    message.end = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.end);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string label_name = 1; */
        if (message.labelName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.labelName);
        /* repeated string match = 2; */
        for (let i = 0; i < message.match.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.match[i]);
        /* google.protobuf.Timestamp start = 3; */
        if (message.start)
            Timestamp.internalBinaryWrite(message.start, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp end = 4; */
        if (message.end)
            Timestamp.internalBinaryWrite(message.end, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.ValuesRequest
 */
export const ValuesRequest = new ValuesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValuesResponse$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.ValuesResponse", [
            { no: 1, name: "label_values", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "warnings", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { labelValues: [], warnings: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string label_values */ 1:
                    message.labelValues.push(reader.string());
                    break;
                case /* repeated string warnings */ 2:
                    message.warnings.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string label_values = 1; */
        for (let i = 0; i < message.labelValues.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.labelValues[i]);
        /* repeated string warnings = 2; */
        for (let i = 0; i < message.warnings.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.warnings[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.ValuesResponse
 */
export const ValuesResponse = new ValuesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValueType$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.ValueType", [
            { no: 1, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "unit", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { type: "", unit: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type */ 1:
                    message.type = reader.string();
                    break;
                case /* string unit */ 2:
                    message.unit = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string type = 1; */
        if (message.type !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.type);
        /* string unit = 2; */
        if (message.unit !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.unit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.ValueType
 */
export const ValueType = new ValueType$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ShareProfileRequest$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.ShareProfileRequest", [
            { no: 1, name: "query_request", kind: "message", T: () => QueryRequest },
            { no: 2, name: "description", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* parca.query.v1alpha1.QueryRequest query_request */ 1:
                    message.queryRequest = QueryRequest.internalBinaryRead(reader, reader.uint32(), options, message.queryRequest);
                    break;
                case /* optional string description */ 2:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* parca.query.v1alpha1.QueryRequest query_request = 1; */
        if (message.queryRequest)
            QueryRequest.internalBinaryWrite(message.queryRequest, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* optional string description = 2; */
        if (message.description !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.ShareProfileRequest
 */
export const ShareProfileRequest = new ShareProfileRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ShareProfileResponse$Type extends MessageType {
    constructor() {
        super("parca.query.v1alpha1.ShareProfileResponse", [
            { no: 1, name: "link", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { link: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string link */ 1:
                    message.link = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string link = 1; */
        if (message.link !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.link);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.query.v1alpha1.ShareProfileResponse
 */
export const ShareProfileResponse = new ShareProfileResponse$Type();
/**
 * @generated ServiceType for protobuf service parca.query.v1alpha1.QueryService
 */
export const QueryService = new ServiceType("parca.query.v1alpha1.QueryService", [
    { name: "QueryRange", options: { "google.api.http": { get: "/profiles/query_range" } }, I: QueryRangeRequest, O: QueryRangeResponse },
    { name: "Query", options: { "google.api.http": { get: "/profiles/query" } }, I: QueryRequest, O: QueryResponse },
    { name: "Series", options: { "google.api.http": { get: "/profiles/series" } }, I: SeriesRequest, O: SeriesResponse },
    { name: "ProfileTypes", options: { "google.api.http": { get: "/profiles/types" } }, I: ProfileTypesRequest, O: ProfileTypesResponse },
    { name: "Labels", options: { "google.api.http": { get: "/profiles/labels" } }, I: LabelsRequest, O: LabelsResponse },
    { name: "Values", options: { "google.api.http": { get: "/profiles/labels/{label_name}/values" } }, I: ValuesRequest, O: ValuesResponse },
    { name: "ShareProfile", options: { "google.api.http": { post: "/profiles/share", body: "*" } }, I: ShareProfileRequest, O: ShareProfileResponse }
]);
