// Copyright 2022 The Parca Authors
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import moment from 'moment-timezone';
export var UNITS = {
    MINUTE: 'minute',
    HOUR: 'hour',
    DAY: 'day',
};
export var POSITIONS = {
    FROM: 'from',
    TO: 'to',
};
var RelativeDate = /** @class */ (function () {
    function RelativeDate(unit, value) {
        this.isRelative = function () { return true; };
        this.unit = unit;
        this.value = value;
    }
    return RelativeDate;
}());
export { RelativeDate };
var AbsoluteDate = /** @class */ (function () {
    function AbsoluteDate(value) {
        this.isRelative = function () { return false; };
        this.value = value !== null && value !== void 0 ? value : getDateHoursAgo(1);
    }
    return AbsoluteDate;
}());
export { AbsoluteDate };
var DateTimeRange = /** @class */ (function () {
    function DateTimeRange(from, to) {
        if (from === void 0) { from = null; }
        if (to === void 0) { to = null; }
        this.from = from !== null && from !== void 0 ? from : new RelativeDate(UNITS.MINUTE, 15);
        this.to = to !== null && to !== void 0 ? to : new RelativeDate(UNITS.MINUTE, 0);
    }
    DateTimeRange.prototype.getRangeStringForUI = function () {
        if (this.from.isRelative() && this.to.isRelative() && this.to.value === 0) {
            var from = this.from;
            return "Last ".concat(from.value, " ").concat(from.unit).concat(from.value > 1 ? 's' : '');
        }
        var formattedFrom = formatDateStringForUI(this.from);
        var formattedTo = formatDateStringForUI(this.to)
            .replace(getUtcStringForDate(this.from, 'll'), '')
            .trim();
        return "".concat(formattedFrom, " \u2192 ").concat(formattedTo);
    };
    DateTimeRange.prototype.getDateForPosition = function (position) {
        if (position === POSITIONS.FROM) {
            return this.from;
        }
        return this.to;
    };
    DateTimeRange.prototype.setDateForPosition = function (date, position) {
        if (position === POSITIONS.FROM) {
            this.from = date;
        }
        else {
            this.to = date;
        }
    };
    DateTimeRange.prototype.getMs = function (date) {
        if (date.isRelative()) {
            return getRelativeDateMs(date);
        }
        return date.value.getTime();
    };
    DateTimeRange.prototype.getFromMs = function () {
        return this.getMs(this.from);
    };
    DateTimeRange.prototype.getToMs = function () {
        return this.getMs(this.to);
    };
    DateTimeRange.prototype.getDateStringKey = function (date) {
        if (date.isRelative()) {
            var relativeDate = date;
            return "".concat(relativeDate.unit, "|").concat(relativeDate.value);
        }
        var absoluteDate = date;
        return "".concat(absoluteDate.value.getTime());
    };
    DateTimeRange.prototype.getFromDateStringKey = function () {
        return this.getDateStringKey(this.from);
    };
    DateTimeRange.prototype.getToDateStringKey = function () {
        return this.getDateStringKey(this.to);
    };
    DateTimeRange.prototype.getRangeKey = function () {
        if (this.from.isRelative()) {
            return "relative:".concat(this.getFromDateStringKey());
        }
        return "absolute:".concat(this.getFromDateStringKey(), "-").concat(this.getToDateStringKey());
    };
    DateTimeRange.fromRangeKey = function (rangeKey) {
        if (rangeKey === undefined) {
            return new DateTimeRange();
        }
        try {
            var _a = rangeKey.split(':'), rangeType = _a[0], rangeValueKey = _a[1];
            if (rangeType === 'relative') {
                var _b = rangeValueKey.split('|'), unit = _b[0], value = _b[1];
                return new DateTimeRange(new RelativeDate(unit, parseInt(value, 10)), new RelativeDate(UNITS.MINUTE, 0));
            }
            if (rangeType === 'absolute') {
                var _c = rangeValueKey.split('-'), fromKey = _c[0], toKey = _c[1];
                return new DateTimeRange(new AbsoluteDate(new Date(parseInt(fromKey, 10))), new AbsoluteDate(new Date(parseInt(toKey, 10))));
            }
            throw new Error('Invalid range key');
        }
        catch (err) {
            console.error('Error while parsing range key', rangeKey, err);
        }
        return new DateTimeRange();
    };
    DateTimeRange.fromAbsoluteDates = function (from, to) {
        return new DateTimeRange(new AbsoluteDate(new Date(from)), new AbsoluteDate(new Date(to)));
    };
    return DateTimeRange;
}());
export { DateTimeRange };
export var formatDateStringForUI = function (dateString) {
    if (dateString.isRelative()) {
        var _a = dateString, unit = _a.unit, value = _a.value;
        if (value === 0) {
            return 'now';
        }
        return "".concat(value, " ").concat(unit).concat(value > 1 ? 's' : '', " ago");
    }
    return getUtcStringForDate(dateString);
};
export var getDateHoursAgo = function (hours) {
    if (hours === void 0) { hours = 1; }
    var now = new Date();
    now.setHours(now.getHours() - hours);
    return now;
};
var getRelativeDateMs = function (date) {
    var now = new Date().getTime();
    var unit = date.unit, value = date.value;
    switch (unit) {
        case UNITS.MINUTE:
            return now - value * 60 * 1000;
        case UNITS.HOUR:
            return now - value * 60 * 60 * 1000;
        case UNITS.DAY:
            return now - value * 24 * 60 * 60 * 1000;
        default:
            return now;
    }
};
export var getUtcStringForDate = function (date, format) {
    if (format === void 0) { format = 'lll'; }
    return moment
        .tz(date.value.toISOString(), Intl.DateTimeFormat().resolvedOptions().timeZone)
        .utc()
        .format(format);
};
