var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
var Card = function (_a) {
    var _b;
    var children = _a.children, _c = _a.className, className = _c === void 0 ? '' : _c;
    return (_jsx("div", __assign({ className: "mx-auto" }, { children: _jsx("div", __assign({ className: cx('g-gray bg-white dark:bg-gray-700 shadow overflow-hidden sm:rounded-lg flex-1 flex-column', (_b = {}, _b[className] = className.length, _b)) }, { children: children })) })));
};
var Header = function (_a) {
    var _b;
    var children = _a.children, _c = _a.className, className = _c === void 0 ? '' : _c;
    return (_jsx("div", __assign({ className: cx('bg-gray-200 dark:bg-gray-800 px-4 py-4 sm:px-6', (_b = {},
            _b[className] = className.length,
            _b)), style: { justifyContent: 'space-between', alignItems: 'stretch' } }, { children: children })));
};
var Body = function (_a) {
    var children = _a.children;
    return _jsx("div", __assign({ className: "p-4" }, { children: children }));
};
export default Object.assign(Card, {
    Header: Header,
    Body: Body,
});
