var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Copyright 2022 The Parca Authors
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useState, useEffect } from 'react';
import { usePopper } from 'react-popper';
import { getLastItem, valueFormatter } from '@parca/functions';
import useIsShiftDown from '@parca/components/src/hooks/useIsShiftDown';
import { hexifyAddress, truncateString } from '../';
var virtualElement = {
    getBoundingClientRect: function () {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return ({
            width: 0,
            height: 0,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        });
    },
};
function generateGetBoundingClientRect(contextElement, x, y) {
    if (x === void 0) { x = 0; }
    if (y === void 0) { y = 0; }
    var domRect = contextElement.getBoundingClientRect();
    return function () {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return ({
            width: 0,
            height: 0,
            top: domRect.y + y,
            left: domRect.x + x,
            right: domRect.x + x,
            bottom: domRect.y + y,
        });
    };
}
var TooltipMetaInfo = function (_a) {
    var _b, _c, _d, _e;
    var hoveringNode = _a.hoveringNode, onCopy = _a.onCopy, strings = _a.strings, mappings = _a.mappings, locations = _a.locations, functions = _a.functions;
    if (hoveringNode.meta === undefined)
        return _jsx(_Fragment, {});
    // populate meta from the flamegraph metadata tables
    if (locations !== undefined &&
        hoveringNode.meta.locationIndex !== undefined &&
        hoveringNode.meta.locationIndex !== 0) {
        var location_1 = locations[hoveringNode.meta.locationIndex - 1];
        hoveringNode.meta.location = location_1;
        if (location_1 !== undefined) {
            if (mappings !== undefined &&
                location_1.mappingIndex !== undefined &&
                location_1.mappingIndex !== 0) {
                var mapping = mappings[location_1.mappingIndex - 1];
                if (strings !== undefined && mapping !== undefined) {
                    mapping.file =
                        (mapping === null || mapping === void 0 ? void 0 : mapping.fileStringIndex) !== undefined ? strings[mapping.fileStringIndex] : '';
                    mapping.buildId =
                        (mapping === null || mapping === void 0 ? void 0 : mapping.buildIdStringIndex) !== undefined ? strings[mapping.buildIdStringIndex] : '';
                }
                hoveringNode.meta.mapping = mapping;
            }
            if (functions !== undefined &&
                location_1.lines !== undefined &&
                hoveringNode.meta.lineIndex !== undefined &&
                hoveringNode.meta.lineIndex < location_1.lines.length) {
                var func = functions[location_1.lines[hoveringNode.meta.lineIndex].functionIndex - 1];
                if (strings !== undefined) {
                    func.name = strings[func.nameStringIndex];
                    func.systemName = strings[func.systemNameStringIndex];
                    func.filename = strings[func.filenameStringIndex];
                }
                hoveringNode.meta.function = func;
            }
        }
    }
    var getTextForFile = function (hoveringNode) {
        var _a, _b, _c, _d, _e;
        if (((_a = hoveringNode.meta) === null || _a === void 0 ? void 0 : _a.function) == null)
            return '<unknown>';
        return "".concat(hoveringNode.meta.function.filename, " ").concat(((_b = hoveringNode.meta.line) === null || _b === void 0 ? void 0 : _b.line) !== undefined && ((_c = hoveringNode.meta.line) === null || _c === void 0 ? void 0 : _c.line) !== '0'
            ? " +".concat(hoveringNode.meta.line.line.toString())
            : "".concat(((_d = hoveringNode.meta.function) === null || _d === void 0 ? void 0 : _d.startLine) !== undefined &&
                ((_e = hoveringNode.meta.function) === null || _e === void 0 ? void 0 : _e.startLine) !== '0'
                ? " +".concat(hoveringNode.meta.function.startLine)
                : ''));
    };
    return (_jsxs(_Fragment, { children: [((_b = hoveringNode.meta.function) === null || _b === void 0 ? void 0 : _b.filename) !== undefined &&
                ((_c = hoveringNode.meta.function) === null || _c === void 0 ? void 0 : _c.filename) !== '' && (_jsxs("tr", { children: [_jsx("td", __assign({ className: "w-1/5" }, { children: "File" })), _jsx("td", __assign({ className: "w-4/5 break-all" }, { children: _jsx(CopyToClipboard, __assign({ onCopy: onCopy, text: getTextForFile(hoveringNode) }, { children: _jsx("button", __assign({ className: "cursor-pointer text-left" }, { children: getTextForFile(hoveringNode) })) })) }))] })), ((_d = hoveringNode.meta.location) === null || _d === void 0 ? void 0 : _d.address) !== undefined &&
                ((_e = hoveringNode.meta.location) === null || _e === void 0 ? void 0 : _e.address) !== '0' && (_jsxs("tr", { children: [_jsx("td", __assign({ className: "w-1/5" }, { children: "Address" })), _jsx("td", __assign({ className: "w-4/5 break-all" }, { children: _jsx(CopyToClipboard, __assign({ onCopy: onCopy, text: hexifyAddress(hoveringNode.meta.location.address) }, { children: _jsx("button", __assign({ className: "cursor-pointer" }, { children: hexifyAddress(hoveringNode.meta.location.address) })) })) }))] })), hoveringNode.meta.mapping !== undefined && hoveringNode.meta.mapping.file !== '' && (_jsxs("tr", { children: [_jsx("td", __assign({ className: "w-1/5" }, { children: "Binary" })), _jsx("td", __assign({ className: "w-4/5 break-all" }, { children: _jsx(CopyToClipboard, __assign({ onCopy: onCopy, text: hoveringNode.meta.mapping.file }, { children: _jsx("button", __assign({ className: "cursor-pointer" }, { children: getLastItem(hoveringNode.meta.mapping.file) })) })) }))] })), hoveringNode.meta.mapping !== undefined && hoveringNode.meta.mapping.buildId !== '' && (_jsxs("tr", { children: [_jsx("td", __assign({ className: "w-1/5" }, { children: "Build Id" })), _jsx("td", __assign({ className: "w-4/5 break-all" }, { children: _jsx(CopyToClipboard, __assign({ onCopy: onCopy, text: hoveringNode.meta.mapping.buildId }, { children: _jsx("button", __assign({ className: "cursor-pointer" }, { children: truncateString(getLastItem(hoveringNode.meta.mapping.buildId), 16) })) })) }))] }))] }));
};
var timeoutHandle = null;
var GraphTooltipContent = function (_a) {
    var hoveringNode = _a.hoveringNode, unit = _a.unit, total = _a.total, isFixed = _a.isFixed, strings = _a.strings, mappings = _a.mappings, locations = _a.locations, functions = _a.functions;
    var _b = useState(false), isCopied = _b[0], setIsCopied = _b[1];
    var onCopy = function () {
        setIsCopied(true);
        if (timeoutHandle !== null) {
            clearTimeout(timeoutHandle);
        }
        timeoutHandle = setTimeout(function () { return setIsCopied(false); }, 3000);
    };
    var hoveringNodeCumulative = parseFloat(hoveringNode.cumulative);
    var diff = hoveringNode.diff === undefined ? 0 : parseFloat(hoveringNode.diff);
    var prevValue = hoveringNodeCumulative - diff;
    var diffRatio = Math.abs(diff) > 0 ? diff / prevValue : 0;
    var diffSign = diff > 0 ? '+' : '';
    var diffValueText = diffSign + valueFormatter(diff, unit, 1);
    var diffPercentageText = diffSign + (diffRatio * 100).toFixed(2) + '%';
    var diffText = "".concat(diffValueText, " (").concat(diffPercentageText, ")");
    var metaRows = hoveringNode.meta === undefined ? (_jsx(_Fragment, {})) : (_jsx(TooltipMetaInfo, { onCopy: onCopy, 
        // @ts-expect-error
        hoveringNode: hoveringNode, strings: strings, mappings: mappings, locations: locations, functions: functions }));
    var getTextForCumulative = function (hoveringNodeCumulative) {
        return "".concat(valueFormatter(hoveringNodeCumulative, unit, 2), " (\n      ").concat(((hoveringNodeCumulative * 100) / total).toFixed(2), "%)");
    };
    return (_jsx("div", __assign({ className: "flex ".concat(isFixed ? 'w-full h-36' : '') }, { children: _jsx("div", __assign({ className: "m-auto w-full ".concat(isFixed ? 'w-full h-36' : '') }, { children: _jsx("div", __assign({ className: "border-gray-300 dark:border-gray-500 bg-gray-50 dark:bg-gray-900 rounded-lg p-3 shadow-lg opacity-90", style: { borderWidth: 1 } }, { children: _jsx("div", __assign({ className: "flex flex-row" }, { children: _jsxs("div", __assign({ className: "ml-2 mr-6" }, { children: [_jsx("span", __assign({ className: "font-semibold break-all" }, { children: hoveringNode.meta === undefined ? (_jsx("p", { children: "root" })) : (_jsx(_Fragment, { children: hoveringNode.meta.function !== undefined &&
                                        hoveringNode.meta.function.name !== '' ? (_jsx(CopyToClipboard, __assign({ onCopy: onCopy, text: hoveringNode.meta.function.name }, { children: _jsx("button", __assign({ className: "cursor-pointer text-left" }, { children: hoveringNode.meta.function.name })) }))) : (_jsx(_Fragment, { children: hoveringNode.meta.location !== undefined &&
                                            parseInt(hoveringNode.meta.location.address, 10) !== 0 ? (_jsx(CopyToClipboard, __assign({ onCopy: onCopy, text: hexifyAddress(hoveringNode.meta.location.address) }, { children: _jsx("button", __assign({ className: "cursor-pointer text-left" }, { children: hexifyAddress(hoveringNode.meta.location.address) })) }))) : (_jsx("p", { children: "unknown" })) })) })) })), _jsx("span", __assign({ className: "text-gray-700 dark:text-gray-300 my-2" }, { children: _jsx("table", __assign({ className: "table-fixed" }, { children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("td", __assign({ className: "w-1/5" }, { children: "Cumulative" })), _jsx("td", __assign({ className: "w-4/5" }, { children: _jsx(CopyToClipboard, __assign({ onCopy: onCopy, text: getTextForCumulative(hoveringNodeCumulative) }, { children: _jsx("button", __assign({ className: "cursor-pointer" }, { children: getTextForCumulative(hoveringNodeCumulative) })) })) }))] }), hoveringNode.diff !== undefined && diff !== 0 && (_jsxs("tr", { children: [_jsx("td", __assign({ className: "w-1/5" }, { children: "Diff" })), _jsx("td", __assign({ className: "w-4/5" }, { children: _jsx(CopyToClipboard, __assign({ onCopy: onCopy, text: diffText }, { children: _jsx("button", __assign({ className: "cursor-pointer" }, { children: diffText })) })) }))] })), metaRows] }) })) })), _jsx("span", __assign({ className: "block text-gray-500 text-xs mt-2" }, { children: isCopied ? 'Copied!' : 'Hold shift and click on a value to copy.' }))] })) })) })) })) })));
};
var GraphTooltip = function (_a) {
    var x = _a.x, y = _a.y, unit = _a.unit, total = _a.total, hoveringNode = _a.hoveringNode, contextElement = _a.contextElement, _b = _a.isFixed, isFixed = _b === void 0 ? false : _b, _c = _a.virtualContextElement, virtualContextElement = _c === void 0 ? true : _c, strings = _a.strings, mappings = _a.mappings, locations = _a.locations, functions = _a.functions;
    var _d = useState(null), popperElement = _d[0], setPopperElement = _d[1];
    var _e = usePopper(virtualContextElement ? virtualElement : contextElement, popperElement, {
        placement: 'auto-start',
        strategy: 'absolute',
        modifiers: [
            {
                name: 'preventOverflow',
                options: {
                    tether: false,
                    altAxis: true,
                },
            },
            {
                name: 'offset',
                options: {
                    offset: [30, 30],
                },
            },
        ],
    }), styles = _e.styles, attributes = _e.attributes, popperProps = __rest(_e, ["styles", "attributes"]);
    var update = popperProps.update;
    var isShiftDown = useIsShiftDown();
    useEffect(function () {
        if (contextElement != null) {
            if (isShiftDown)
                return;
            virtualElement.getBoundingClientRect = generateGetBoundingClientRect(contextElement, x, y);
            void (update === null || update === void 0 ? void 0 : update());
        }
    }, [x, y, contextElement, update, isShiftDown]);
    if (hoveringNode === undefined || hoveringNode == null)
        return _jsx(_Fragment, {});
    return isFixed ? (_jsx(GraphTooltipContent, { hoveringNode: hoveringNode, unit: unit, total: total, isFixed: isFixed })) : (_jsx("div", __assign({ ref: setPopperElement, style: styles.popper }, attributes.popper, { children: _jsx(GraphTooltipContent, { hoveringNode: hoveringNode, unit: unit, total: total, isFixed: isFixed, strings: strings, mappings: mappings, locations: locations, functions: functions }) })));
};
export default GraphTooltip;
