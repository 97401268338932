var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// Copyright 2022 The Parca Authors
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import cx from 'classnames';
var Modal = function (_a) {
    var _b;
    var isOpen = _a.isOpen, closeModal = _a.closeModal, title = _a.title, children = _a.children, _c = _a.className, className = _c === void 0 ? 'max-w-md' : _c;
    return (_jsx(_Fragment, { children: _jsx(Transition, __assign({ appear: true, show: isOpen, as: Fragment }, { children: _jsx(Dialog, __assign({ as: "div", className: "fixed inset-0 z-10 overflow-y-auto bg-black/60", onClose: closeModal }, { children: _jsxs("div", __assign({ className: "min-h-screen px-4 text-center" }, { children: [_jsx(Transition.Child, __assign({ as: Fragment, enter: "ease-out duration-300", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "ease-in duration-200", leaveFrom: "opacity-100", leaveTo: "opacity-0" }, { children: _jsx(Dialog.Overlay, { className: "fixed inset-0" }) })), _jsx("span", __assign({ className: "inline-block h-screen align-middle", "aria-hidden": "true" }, { children: "\u200B" })), _jsx(Transition.Child, __assign({ as: Fragment, enter: "ease-out duration-300", enterFrom: "opacity-0 scale-95", enterTo: "opacity-100 scale-100", leave: "ease-in duration-200", leaveFrom: "opacity-100 scale-100", leaveTo: "opacity-0 scale-95" }, { children: _jsxs("div", __assign({ className: cx('inline-block w-fit min-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-700 shadow-xl rounded-2xl', (_b = {}, _b[className] = (className === null || className === void 0 ? void 0 : className.length) > 0, _b)) }, { children: [_jsx(Dialog.Title, __assign({ as: "h3", className: "text-lg font-medium leading-6" }, { children: title })), children] })) }))] })) })) })) }));
};
export default Modal;
