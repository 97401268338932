// Copyright 2022 The Parca Authors
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { createSlice } from '@reduxjs/toolkit';
// Define the initial state using that type
var initialState = {
    darkMode: false,
};
export var uiSlice = createSlice({
    name: 'ui',
    initialState: initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setDarkMode: function (state, action) {
            state.darkMode = action.payload;
        },
    },
});
export var setDarkMode = uiSlice.actions.setDarkMode;
// Other code such as selectors can use the imported `RootState` type
export var selectDarkMode = function (state) { return state.ui.darkMode; };
export default uiSlice.reducer;
