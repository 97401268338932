var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSelector, selectCompareMode } from '@parca/store';
import { useContainerDimensions } from '@parca/dynamicsize';
import DiffLegend from '../components/DiffLegend';
import IcicleGraph from '../IcicleGraph';
var ProfileIcicleGraph = function (_a) {
    var graph = _a.graph, curPath = _a.curPath, setNewCurPath = _a.setNewCurPath, sampleUnit = _a.sampleUnit;
    var compareMode = useAppSelector(selectCompareMode);
    var _b = useContainerDimensions(), ref = _b.ref, dimensions = _b.dimensions;
    if (graph === undefined)
        return _jsx("div", { children: "no data..." });
    var total = graph.total;
    if (parseFloat(total) === 0)
        return _jsx(_Fragment, { children: "Profile has no samples" });
    return (_jsxs(_Fragment, { children: [compareMode && _jsx(DiffLegend, {}), _jsx("div", __assign({ ref: ref }, { children: _jsx(IcicleGraph, { width: dimensions === null || dimensions === void 0 ? void 0 : dimensions.width, graph: graph, curPath: curPath, setCurPath: setNewCurPath, sampleUnit: sampleUnit }) }))] }));
};
export default ProfileIcicleGraph;
