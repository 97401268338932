// @generated by protobuf-ts 2.8.2 with parameter long_type_string,generate_dependencies
// @generated from protobuf file "parca/profilestore/v1alpha1/profilestore.proto" (package "parca.profilestore.v1alpha1", syntax proto3)
// tslint:disable
import { AgentsService } from "./profilestore";
import { ProfileStoreService } from "./profilestore";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * ProfileStoreService is the service the accepts pprof writes
 *
 * @generated from protobuf service parca.profilestore.v1alpha1.ProfileStoreService
 */
export class ProfileStoreServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = ProfileStoreService.typeName;
        this.methods = ProfileStoreService.methods;
        this.options = ProfileStoreService.options;
    }
    /**
     * WriteRaw accepts a raw set of bytes of a pprof file
     *
     * @generated from protobuf rpc: WriteRaw(parca.profilestore.v1alpha1.WriteRawRequest) returns (parca.profilestore.v1alpha1.WriteRawResponse);
     */
    writeRaw(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
/**
 * AgentsService maintains the agents
 *
 * @generated from protobuf service parca.profilestore.v1alpha1.AgentsService
 */
export class AgentsServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = AgentsService.typeName;
        this.methods = AgentsService.methods;
        this.options = AgentsService.options;
    }
    /**
     * Agents return the agents that pushed data to the server
     *
     * @generated from protobuf rpc: Agents(parca.profilestore.v1alpha1.AgentsRequest) returns (parca.profilestore.v1alpha1.AgentsResponse);
     */
    agents(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
