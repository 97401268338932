var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
// Copyright 2022 The Parca Authors
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import cx from 'classnames';
var BUTTON_VARIANT = {
    primary: {
        text: 'text-gray-100 dark-gray-900 justify-center',
        bg: 'bg-indigo-600',
        border: 'border border-indigo-500',
        fontWeight: 'font-medium',
        hover: '',
        padding: 'py-2 px-4',
    },
    neutral: {
        text: 'text-gray-600 dark:text-gray-100 justify-center',
        bg: 'bg-gray-50 dark:bg-gray-900',
        border: 'border border-gray-200 dark:border-gray-600',
        fontWeight: 'font-normal',
        hover: '',
        padding: 'py-2 px-4',
    },
    link: {
        text: 'text-gray-600 dark:text-gray-300 justify-start',
        bg: '',
        border: '',
        fontWeight: 'font-normal',
        hover: 'hover:underline p-0',
        padding: 'py-1',
    },
};
var Button = function (_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.variant, variant = _c === void 0 ? 'primary' : _c, children = _a.children, _d = _a.className, className = _d === void 0 ? '' : _d, props = __rest(_a, ["disabled", "variant", "children", "className"]);
    return (_jsx("button", __assign({ type: "button", className: cx.apply(void 0, __spreadArray(__spreadArray([disabled ? 'opacity-50 pointer-events-none' : ''], Object.values(BUTTON_VARIANT[variant]), false), ['cursor-pointer group relative w-full flex text-sm rounded-md text-whitefocus:outline-none focus:ring-2 focus:ring-offset-2 items-center justify-center',
            className], false)), disabled: disabled }, props, { children: children })));
};
export default Button;
export var IconButton = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, props = __rest(_a, ["className"]);
    return _jsx(Button, __assign({}, props, { variant: "link", className: "w-fit ".concat(className) }));
};
export var PageButton = function (_a) {
    var children = _a.children, _b = _a.className, className = _b === void 0 ? '' : _b, rest = __rest(_a, ["children", "className"]);
    return (_jsx("button", __assign({ type: "button", className: cx('relative inline-flex items-center px-2 py-2 border border-gray-300 dark:border-none bg-white dark:bg-gray-900 text-sm font-medium text-gray-500 hover:bg-gray-50', className) }, rest, { children: children })));
};
