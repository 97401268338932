var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Copyright 2022 The Parca Authors
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { useAppSelector, selectDarkMode } from '@parca/store';
import { getNewSpanColor, getIncreasedSpanColor, getReducedSpanColor } from '@parca/functions';
import { usePopper } from 'react-popper';
var transparencyValues = [-100, -80, -60, -40, -20, 0, 20, 40, 60, 80, 100];
var DiffLegendBar = function (_a) {
    var onMouseEnter = _a.onMouseEnter, onMouseLeave = _a.onMouseLeave;
    var isDarkMode = useAppSelector(selectDarkMode);
    return (_jsx("div", __assign({ className: "flex items-center m-2" }, { children: transparencyValues.map(function (value) {
            var valueAsPercentage = value / 100;
            var absoluteValue = Math.abs(valueAsPercentage);
            return (_jsx("div", { onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, className: "w-8 h-4", style: {
                    backgroundColor: absoluteValue === 0
                        ? getNewSpanColor(isDarkMode)
                        : valueAsPercentage > 0
                            ? getIncreasedSpanColor(absoluteValue, isDarkMode)
                            : getReducedSpanColor(absoluteValue, isDarkMode),
                } }, valueAsPercentage));
        }) })));
};
var DiffLegend = function () {
    var _a = useState(false), showLegendTooltip = _a[0], setShowLegendTooltip = _a[1];
    var _b = useState(null), popperElement = _b[0], setPopperElement = _b[1];
    var _c = useState(null), referenceElement = _c[0], setReferenceElement = _c[1];
    var _d = usePopper(referenceElement, popperElement, {
        placement: 'auto-start',
        strategy: 'absolute',
    }), styles = _d.styles, attributes = _d.attributes;
    var handleMouseEnter = function () {
        setShowLegendTooltip(true);
    };
    var handleMouseLeave = function () {
        setShowLegendTooltip(false);
    };
    return (_jsxs("div", __assign({ className: "mt-1 mb-2" }, { children: [_jsxs("div", __assign({ ref: setReferenceElement, className: "flex items-center justify-center" }, { children: [_jsx("span", { children: "Better" }), _jsx(DiffLegendBar, { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave }), _jsx("span", { children: "Worse" })] })), _jsx(Popover, __assign({ className: "relative" }, { children: function () { return (_jsx(Transition, __assign({ show: showLegendTooltip, as: Fragment, enter: "transition ease-out duration-200", enterFrom: "opacity-0 translate-y-1", enterTo: "opacity-100 translate-y-0", leave: "transition ease-in duration-150", leaveFrom: "opacity-100 translate-y-0", leaveTo: "opacity-0 translate-y-1" }, { children: _jsx(Popover.Panel, __assign({ ref: setPopperElement, style: styles.popper }, attributes.popper, { children: _jsx("div", __assign({ className: "overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5" }, { children: _jsxs("div", __assign({ className: "p-4 bg-gray-50 dark:bg-gray-800" }, { children: [_jsx("div", { className: "flex items-center justify-center" }), _jsx("span", __assign({ className: "block text-sm text-gray-500 dark:text-gray-50" }, { children: "This is a differential icicle graph, where a purple-colored node means unchanged, and the darker the red, the worse the node got, and the darker the green, the better the node got." }))] })) })) })) }))); } }))] })));
};
export default DiffLegend;
