var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// Copyright 2022 The Parca Authors
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import Button from '../Button';
import cx from 'classnames';
var Dropdown = function (_a) {
    var text = _a.text, element = _a.element, _b = _a.variant, variant = _b === void 0 ? 'neutral' : _b, children = _a.children;
    return (_jsx(_Fragment, { children: _jsxs(Menu, __assign({ as: "div", className: "relative inline-block text-left" }, { children: [_jsx("div", { children: _jsx(Menu.Button, __assign({ as: "div" }, { children: element !== undefined ? (_jsxs(Button, __assign({ variant: variant }, { children: [text, ' ', _jsx(ChevronDownIcon, { className: "w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100", "aria-hidden": "true" })] }))) : (_jsx("div", __assign({ className: "cursor-pointer" }, { children: element }))) })) }), _jsx(Transition, __assign({ as: Fragment, enter: "transition ease-out duration-100", enterFrom: "transform opacity-0 scale-95", enterTo: "transform opacity-100 scale-100", leave: "transition ease-in duration-75", leaveFrom: "transform opacity-100 scale-100", leaveTo: "transform opacity-0 scale-95" }, { children: _jsx(Menu.Items, __assign({ className: "absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" }, { children: _jsx("div", __assign({ className: "px-1 py-1 " }, { children: children })) })) }))] })) }));
};
var Item = function (_a) {
    var children = _a.children, onSelect = _a.onSelect;
    return (_jsx(Menu.Item, { children: function (_a) {
            var active = _a.active;
            return (_jsx("button", __assign({ className: cx(active ? 'bg-indigo-500 text-white' : 'text-gray-900', 'group flex rounded-md items-center w-full px-2 py-2 text-sm'), onClick: onSelect }, { children: children })));
        } }));
};
export default Object.assign(Dropdown, {
    Item: Item,
});
