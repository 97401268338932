var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// Copyright 2022 The Parca Authors
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { throttle } from 'lodash';
import { pointer } from 'd3-selection';
import { scaleLinear } from 'd3-scale';
import GraphTooltip from './GraphTooltip';
import { diffColor, getLastItem, isSearchMatch } from '@parca/functions';
import { selectDarkMode, selectSearchNodeString, useAppSelector } from '@parca/store';
import useIsShiftDown from '@parca/components/src/hooks/useIsShiftDown';
import { hexifyAddress } from './utils';
var RowHeight = 26;
var icicleRectStyles = {
    cursor: 'pointer',
    transition: 'opacity .15s linear',
};
var fadedIcicleRectStyles = {
    cursor: 'pointer',
    transition: 'opacity .15s linear',
    opacity: '0.5',
};
function IcicleRect(_a) {
    var x = _a.x, y = _a.y, width = _a.width, height = _a.height, color = _a.color, name = _a.name, onMouseEnter = _a.onMouseEnter, onMouseLeave = _a.onMouseLeave, onClick = _a.onClick, curPath = _a.curPath;
    var currentSearchString = useAppSelector(selectSearchNodeString);
    var isFaded = curPath.length > 0 && name !== curPath[curPath.length - 1];
    var styles = isFaded ? fadedIcicleRectStyles : icicleRectStyles;
    return (_jsxs("g", __assign({ transform: "translate(".concat(x + 1, ", ").concat(y + 1, ")"), style: styles, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onClick: onClick }, { children: [_jsx("rect", { x: 0, y: 0, width: width - 1, height: height - 1, style: {
                    opacity: currentSearchString !== undefined &&
                        currentSearchString !== '' &&
                        !isSearchMatch(currentSearchString, name)
                        ? 0.5
                        : 1,
                    fill: color,
                } }), width > 5 && (_jsx("svg", __assign({ width: width - 5, height: height }, { children: _jsx("text", __assign({ x: 5, y: 15, style: { fontSize: '12px' } }, { children: name })) })))] })));
}
export function nodeLabel(node, strings, mappings, locations, functions) {
    var _a, _b, _c;
    if (((_a = node.meta) === null || _a === void 0 ? void 0 : _a.locationIndex) === undefined)
        return '<unknown>';
    if (((_b = node.meta) === null || _b === void 0 ? void 0 : _b.locationIndex) === 0)
        return '<unknown>';
    var location = locations[node.meta.locationIndex - 1];
    var mapping = location.mappingIndex !== undefined || location.mappingIndex !== 0
        ? mappings[location.mappingIndex - 1]
        : undefined;
    var mappingFile = (mapping === null || mapping === void 0 ? void 0 : mapping.fileStringIndex) !== undefined ? strings[mapping.fileStringIndex] : '';
    var mappingString = "".concat(mappingFile !== '' ? '[' + ((_c = getLastItem(mappingFile)) !== null && _c !== void 0 ? _c : '') + '] ' : '');
    if (location.lines.length > 0) {
        var funcName = strings[functions[location.lines[node.meta.lineIndex].functionIndex - 1].nameStringIndex];
        return "".concat(mappingString, " ").concat(funcName);
    }
    var address = hexifyAddress(location.address);
    var fallback = "".concat(mappingString).concat(address);
    return fallback === '' ? '<unknown>' : fallback;
}
export function IcicleGraphNodes(_a) {
    var data = _a.data, strings = _a.strings, mappings = _a.mappings, locations = _a.locations, functions = _a.functions, x = _a.x, y = _a.y, xScale = _a.xScale, total = _a.total, totalWidth = _a.totalWidth, level = _a.level, setHoveringNode = _a.setHoveringNode, path = _a.path, setCurPath = _a.setCurPath, curPath = _a.curPath;
    var isDarkMode = useAppSelector(selectDarkMode);
    var isShiftDown = useIsShiftDown();
    var nodes = curPath.length === 0
        ? data
        : data.filter(function (d) { return d != null && curPath[0] === nodeLabel(d, strings, mappings, locations, functions); });
    var nextLevel = level + 1;
    return (_jsx("g", __assign({ transform: "translate(".concat(x, ", ").concat(y, ")") }, { children: nodes.map(function (d, i) {
            var cumulative = parseFloat(d.cumulative);
            var diff = parseFloat(d.diff);
            var start = nodes.slice(0, i).reduce(function (sum, d) { return sum + parseFloat(d.cumulative); }, 0);
            var nextCurPath = curPath.length === 0 ? [] : curPath.slice(1);
            var width = nextCurPath.length > 0 || (nextCurPath.length === 0 && curPath.length === 1)
                ? totalWidth
                : xScale(cumulative);
            if (width <= 1) {
                return null;
            }
            var name = nodeLabel(d, strings, mappings, locations, functions);
            var key = "".concat(level, "-").concat(i);
            var nextPath = path.concat([name]);
            var color = diffColor(diff, cumulative, isDarkMode);
            var onClick = function () {
                setCurPath(nextPath);
            };
            var xStart = xScale(start);
            var newXScale = nextCurPath.length === 0 && curPath.length === 1
                ? scaleLinear().domain([0, cumulative]).range([0, totalWidth])
                : xScale;
            var onMouseEnter = function () {
                if (isShiftDown)
                    return;
                setHoveringNode(d);
            };
            var onMouseLeave = function () {
                if (isShiftDown)
                    return;
                setHoveringNode(undefined);
            };
            return (_jsxs(React.Fragment, { children: [_jsx(IcicleRect, { x: xStart, y: 0, width: width, height: RowHeight, name: name, color: color, onClick: onClick, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, curPath: curPath }, "rect-".concat(key)), data !== undefined && data.length > 0 && (_jsx(IcicleGraphNodes, { data: d.children, strings: strings, mappings: mappings, locations: locations, functions: functions, x: xStart, y: RowHeight, xScale: newXScale, total: total, totalWidth: totalWidth, level: nextLevel, setHoveringNode: setHoveringNode, path: nextPath, curPath: nextCurPath, setCurPath: setCurPath }, "node-".concat(key)))] }, "node-".concat(key)));
        }) })));
}
var MemoizedIcicleGraphNodes = React.memo(IcicleGraphNodes);
export function IcicleGraphRootNode(_a) {
    var node = _a.node, strings = _a.strings, mappings = _a.mappings, locations = _a.locations, functions = _a.functions, xScale = _a.xScale, total = _a.total, totalWidth = _a.totalWidth, setHoveringNode = _a.setHoveringNode, setCurPath = _a.setCurPath, curPath = _a.curPath;
    var isDarkMode = useAppSelector(selectDarkMode);
    var isShiftDown = useIsShiftDown();
    var cumulative = parseFloat(node.cumulative);
    var diff = parseFloat(node.diff);
    var color = diffColor(diff, cumulative, isDarkMode);
    var onClick = function () { return setCurPath([]); };
    var onMouseEnter = function () {
        if (isShiftDown)
            return;
        setHoveringNode(node);
    };
    var onMouseLeave = function () {
        if (isShiftDown)
            return;
        setHoveringNode(undefined);
    };
    var path = [];
    return (_jsxs("g", __assign({ transform: 'translate(0, 0)' }, { children: [_jsx(IcicleRect, { x: 0, y: 0, width: totalWidth, height: RowHeight, name: 'root', color: color, onClick: onClick, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, curPath: curPath }), _jsx(MemoizedIcicleGraphNodes, { data: node.children, strings: strings, mappings: mappings, locations: locations, functions: functions, x: 0, y: RowHeight, xScale: xScale, total: total, totalWidth: totalWidth, level: 0, setHoveringNode: setHoveringNode, path: path, curPath: curPath, setCurPath: setCurPath })] })));
}
var MemoizedIcicleGraphRootNode = React.memo(IcicleGraphRootNode);
export default function IcicleGraph(_a) {
    var graph = _a.graph, width = _a.width, setCurPath = _a.setCurPath, curPath = _a.curPath, sampleUnit = _a.sampleUnit;
    var _b = useState(), hoveringNode = _b[0], setHoveringNode = _b[1];
    var _c = useState([0, 0]), pos = _c[0], setPos = _c[1];
    var _d = useState(0), height = _d[0], setHeight = _d[1];
    var svg = useRef(null);
    var ref = useRef(null);
    useEffect(function () {
        if (ref.current != null) {
            setHeight(ref === null || ref === void 0 ? void 0 : ref.current.getBoundingClientRect().height);
        }
    }, [width]);
    var total = useMemo(function () { return parseFloat(graph.total); }, [graph.total]);
    var xScale = useMemo(function () {
        if (width === undefined) {
            return function () { return 0; };
        }
        return scaleLinear().domain([0, total]).range([0, width]);
    }, [total, width]);
    if (graph.root === undefined || width === undefined) {
        return _jsx(_Fragment, {});
    }
    var throttledSetPos = throttle(setPos, 20);
    var onMouseMove = function (e) {
        // X/Y coordinate array relative to svg
        var rel = pointer(e);
        throttledSetPos([rel[0], rel[1]]);
    };
    return (_jsxs("div", __assign({ onMouseLeave: function () { return setHoveringNode(undefined); } }, { children: [_jsx(GraphTooltip, { unit: sampleUnit, total: total, x: pos[0], y: pos[1], hoveringNode: hoveringNode, contextElement: svg.current, strings: graph.stringTable, mappings: graph.mapping, locations: graph.locations, functions: graph.function }), _jsx("svg", __assign({ className: "font-robotoMono", width: width, height: height, onMouseMove: onMouseMove, preserveAspectRatio: "xMinYMid", ref: svg }, { children: _jsx("g", __assign({ ref: ref }, { children: _jsx(MemoizedIcicleGraphRootNode, { node: graph.root, strings: graph.stringTable, mappings: graph.mapping, locations: graph.locations, functions: graph.function, setHoveringNode: setHoveringNode, curPath: curPath, setCurPath: setCurPath, xScale: xScale, total: total, totalWidth: width }) })) }))] })));
}
