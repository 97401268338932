// @generated by protobuf-ts 2.8.2 with parameter long_type_string,generate_dependencies
// @generated from protobuf file "grpc/health/v1/health.proto" (package "grpc.health.v1", syntax proto3)
// tslint:disable
//
// Copyright 2015 The gRPC Authors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
//
// The canonical version of this proto can be found at
// https://github.com/grpc/grpc-proto/blob/master/grpc/health/v1/health.proto
//
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf enum grpc.health.v1.HealthCheckResponse.ServingStatus
 */
export var HealthCheckResponse_ServingStatus;
(function (HealthCheckResponse_ServingStatus) {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    HealthCheckResponse_ServingStatus[HealthCheckResponse_ServingStatus["UNKNOWN"] = 0] = "UNKNOWN";
    /**
     * @generated from protobuf enum value: SERVING = 1;
     */
    HealthCheckResponse_ServingStatus[HealthCheckResponse_ServingStatus["SERVING"] = 1] = "SERVING";
    /**
     * @generated from protobuf enum value: NOT_SERVING = 2;
     */
    HealthCheckResponse_ServingStatus[HealthCheckResponse_ServingStatus["NOT_SERVING"] = 2] = "NOT_SERVING";
    /**
     * Used only by the Watch method.
     *
     * @generated from protobuf enum value: SERVICE_UNKNOWN = 3;
     */
    HealthCheckResponse_ServingStatus[HealthCheckResponse_ServingStatus["SERVICE_UNKNOWN"] = 3] = "SERVICE_UNKNOWN";
})(HealthCheckResponse_ServingStatus || (HealthCheckResponse_ServingStatus = {}));
// @generated message type with reflection information, may provide speed optimized methods
class HealthCheckRequest$Type extends MessageType {
    constructor() {
        super("grpc.health.v1.HealthCheckRequest", [
            { no: 1, name: "service", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { service: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string service */ 1:
                    message.service = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string service = 1; */
        if (message.service !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.service);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpc.health.v1.HealthCheckRequest
 */
export const HealthCheckRequest = new HealthCheckRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HealthCheckResponse$Type extends MessageType {
    constructor() {
        super("grpc.health.v1.HealthCheckResponse", [
            { no: 1, name: "status", kind: "enum", T: () => ["grpc.health.v1.HealthCheckResponse.ServingStatus", HealthCheckResponse_ServingStatus] }
        ]);
    }
    create(value) {
        const message = { status: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* grpc.health.v1.HealthCheckResponse.ServingStatus status */ 1:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* grpc.health.v1.HealthCheckResponse.ServingStatus status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message grpc.health.v1.HealthCheckResponse
 */
export const HealthCheckResponse = new HealthCheckResponse$Type();
/**
 * @generated ServiceType for protobuf service grpc.health.v1.Health
 */
export const Health = new ServiceType("grpc.health.v1.Health", [
    { name: "Check", options: {}, I: HealthCheckRequest, O: HealthCheckResponse },
    { name: "Watch", serverStreaming: true, options: {}, I: HealthCheckRequest, O: HealthCheckResponse }
]);
