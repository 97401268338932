var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// Copyright 2022 The Parca Authors
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { useState } from 'react';
import { Button, Modal, useGrpcMetadata } from '@parca/components';
import { Icon } from '@iconify/react';
import ResultBox from './ResultBox';
var ProfileShareModal = function (_a) {
    var isOpen = _a.isOpen, closeModal = _a.closeModal, queryRequest = _a.queryRequest, queryClient = _a.queryClient;
    var _b = useState(false), isShared = _b[0], setIsShared = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState(''), error = _d[0], setError = _d[1];
    var _e = useState(''), description = _e[0], setDescription = _e[1];
    var _f = useState(''), sharedLink = _f[0], setSharedLink = _f[1];
    var metadata = useGrpcMetadata();
    var isFormDataValid = function () { return true; };
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setLoading(true);
                    return [4 /*yield*/, queryClient.shareProfile({ queryRequest: queryRequest, description: description }, { meta: metadata })];
                case 1:
                    response = (_a.sent()).response;
                    setSharedLink(response.link);
                    setLoading(false);
                    setIsShared(true);
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    if (err_1 instanceof Error) {
                        console.error(err_1);
                        setLoading(false);
                        // https://github.com/microsoft/TypeScript/issues/38347
                        // eslint-disable-next-line @typescript-eslint/no-base-to-string
                        setError(err_1.toString());
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onClose = function () {
        setLoading(false);
        setError('');
        setDescription('');
        setIsShared(false);
        closeModal();
    };
    return (_jsx(Modal, __assign({ isOpen: isOpen, closeModal: onClose, title: "Share Profile", className: "w-[420px]" }, { children: _jsxs("form", __assign({ className: "py-2" }, { children: [_jsx("p", __assign({ className: "text-sm text-gray-500 dark:text-gray-300" }, { children: "Note: Shared profiles can be accessed by anyone with the link, even from people outside your organisation." })), !isShared || (error === null || error === void 0 ? void 0 : error.length) > 0 ? (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: "text-sm text-gray-500 dark:text-gray-300 mt-3 mb-2" }, { children: "Enter a description (optional)" })), _jsx("textarea", { className: "border w-full text-gray-500 dark:text-gray-300 bg-inherit text-sm px-2 py-2", value: description, onChange: function (e) { return setDescription(e.target.value); } }), _jsx(Button, __assign({ className: "w-fit mt-4", onClick: function (e) {
                                e.preventDefault();
                                void handleSubmit();
                            }, disabled: loading || !isFormDataValid(), type: "submit" }, { children: loading ? 'Sharing' : 'Share' })), error !== '' ? _jsx("p", { children: "Something went wrong please try again" }) : null] })) : (_jsxs(_Fragment, { children: [_jsx(ResultBox, { value: sharedLink, className: "mt-4" }), _jsx("div", __assign({ className: "flex justify-center mt-8" }, { children: _jsx(Button, __assign({ variant: "neutral", className: "w-fit", onClick: onClose }, { children: "Close" })) }))] }))] })) })));
};
var ProfileShareButton = function (_a) {
    var queryRequest = _a.queryRequest, queryClient = _a.queryClient, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var _c = useState(false), isOpen = _c[0], setIsOpen = _c[1];
    return (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ color: "neutral", className: "w-fit", onClick: function () { return setIsOpen(true); }, disabled: disabled }, { children: _jsx(Icon, { icon: "ei:share-apple", width: 20 }) })), _jsx(ProfileShareModal, { isOpen: isOpen, closeModal: function () { return setIsOpen(false); }, queryRequest: queryRequest, queryClient: queryClient })] }));
};
export default ProfileShareButton;
