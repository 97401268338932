var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Copyright 2022 The Parca Authors
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { useState } from 'react';
import cx from 'classnames';
import { Icon } from '@iconify/react';
import { Button } from '@parca/components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
var timeoutHandle = null;
var ResultBox = function (_a) {
    var _b;
    var value = _a.value, _c = _a.className, className = _c === void 0 ? '' : _c;
    var _d = useState(false), isCopied = _d[0], setIsCopied = _d[1];
    var onCopy = function () {
        var _a, _b;
        setIsCopied(true);
        (_b = (_a = window.document) === null || _a === void 0 ? void 0 : _a.activeElement) === null || _b === void 0 ? void 0 : _b.blur();
        if (timeoutHandle != null) {
            clearTimeout(timeoutHandle);
        }
        timeoutHandle = setTimeout(function () { return setIsCopied(false); }, 3000);
    };
    return (_jsxs("div", __assign({ className: cx('flex flex-row w-full', (_b = {}, _b[className] = (className === null || className === void 0 ? void 0 : className.length) > 0, _b)) }, { children: [_jsx("span", __assign({ className: "flex justify-center items-center border border-r-0 w-16 rounded-l" }, { children: _jsx(Icon, { icon: "ant-design:link-outlined" }) })), _jsx("input", { type: "text", className: "border text-sm bg-inherit w-full px-1 py-2 flex-grow", value: value, readOnly: true }), _jsx(CopyToClipboard, __assign({ text: value, onCopy: onCopy }, { children: _jsx(Button, __assign({ variant: "link", className: "border border-l-0 w-fit whitespace-nowrap p-4 items-center !text-indigo-600 dark:!text-indigo-400 rounded-none rounded-r" }, { children: isCopied ? 'Copied!' : 'Copy Link' })) }))] })));
};
export default ResultBox;
