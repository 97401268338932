var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Copyright 2022 The Parca Authors
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { useState } from 'react';
import { capitalize } from '@parca/functions';
import Input from '../../../Input';
import { RelativeDate, UNITS } from '../../utils';
import Button from '../../../Button';
import Select, { contructItemsFromArray } from '../../../Select';
var constructKeyAndLabels = function (UNITS) {
    return UNITS.map(function (unit) { return ({
        key: unit,
        label: "".concat(capitalize(unit), "s"),
    }); });
};
var quickPresetRanges = [
    {
        title: 'Last 15 min',
        unit: UNITS.MINUTE,
        value: 15,
    },
    {
        title: 'Last 1 hour',
        unit: UNITS.HOUR,
        value: 1,
    },
    {
        title: 'Last 3 hours',
        unit: UNITS.HOUR,
        value: 3,
    },
    {
        title: 'Last 6 hours',
        unit: UNITS.HOUR,
        value: 6,
    },
    {
        title: 'Last 12 hours',
        unit: UNITS.HOUR,
        value: 12,
    },
    {
        title: 'Last 1 day',
        unit: UNITS.DAY,
        value: 1,
    },
];
var NOW = new RelativeDate(UNITS.MINUTE, 0);
var RelativeDatePicker = function (_a) {
    var range = _a.range, _b = _a.onChange, onChange = _b === void 0 ? function () { return null; } : _b;
    var date = range.from;
    var _c = useState(date.isRelative() ? date.unit : UNITS.MINUTE), unit = _c[0], setUnit = _c[1];
    var _d = useState(date.isRelative() ? date.value : 15), value = _d[0], setValue = _d[1];
    return (_jsxs("div", __assign({ className: "p-4 w-[300px]" }, { children: [_jsxs("div", __assign({ className: "pb-2" }, { children: [_jsx("div", __assign({ className: "mb-4 hidden" }, { children: _jsx("span", __assign({ className: "uppercase text-xs text-gray-500" }, { children: "Quick Ranges" })) })), _jsx("div", __assign({ className: "grid grid-rows-3 grid-flow-col gap-2" }, { children: quickPresetRanges.map(function (_a) {
                            var title = _a.title, unit = _a.unit, value = _a.value;
                            return (_jsx(Button, __assign({ onClick: function () {
                                    onChange(new RelativeDate(unit, value), NOW);
                                }, color: "link" }, { children: title }), title));
                        }) }))] })), _jsxs("div", { children: [_jsx("div", __assign({ className: "my-4 border-b-[1px] border-gray-200 text-xs leading-[0px] mx-auto text-center" }, { children: _jsx("span", __assign({ className: "bg-gray-100 dark:bg-gray-800 px-1" }, { children: "OR" })) })), _jsxs("div", __assign({ className: "flex items-center justify-center p-1 my-4" }, { children: [_jsx("span", __assign({ className: "uppercase text-xs mr-4" }, { children: "Last" })), _jsx(Input, { type: "number", className: "w-16 mr-2 text-sm", value: value, min: 0, onChange: function (e) {
                                    return setValue(parseInt(e.target.value, 10));
                                } }), _jsx(Select, { className: "w-32", items: contructItemsFromArray(constructKeyAndLabels(Object.values(UNITS))), selectedKey: unit, onSelection: function (key) { return setUnit(key); } })] })), _jsx("div", __assign({ className: "w-32 mx-auto pb-2" }, { children: _jsx(Button, __assign({ onClick: function () {
                                onChange(new RelativeDate(unit, value), NOW);
                            } }, { children: "Apply" })) }))] }), _jsx("p", __assign({ className: "text-gray-500 text-xs italic text-center mx-4" }, { children: "Note: Setting a relative time means that on every search the time will be set to the time of the search." }))] })));
};
export default RelativeDatePicker;
