// @generated by protobuf-ts 2.8.2 with parameter long_type_string,generate_dependencies
// @generated from protobuf file "parca/metastore/v1alpha1/metastore.proto" (package "parca.metastore.v1alpha1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class GetOrCreateMappingsRequest$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.GetOrCreateMappingsRequest", [
            { no: 1, name: "mappings", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Mapping }
        ]);
    }
    create(value) {
        const message = { mappings: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.metastore.v1alpha1.Mapping mappings */ 1:
                    message.mappings.push(Mapping.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.metastore.v1alpha1.Mapping mappings = 1; */
        for (let i = 0; i < message.mappings.length; i++)
            Mapping.internalBinaryWrite(message.mappings[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.GetOrCreateMappingsRequest
 */
export const GetOrCreateMappingsRequest = new GetOrCreateMappingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrCreateMappingsResponse$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.GetOrCreateMappingsResponse", [
            { no: 1, name: "mappings", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Mapping }
        ]);
    }
    create(value) {
        const message = { mappings: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.metastore.v1alpha1.Mapping mappings */ 1:
                    message.mappings.push(Mapping.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.metastore.v1alpha1.Mapping mappings = 1; */
        for (let i = 0; i < message.mappings.length; i++)
            Mapping.internalBinaryWrite(message.mappings[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.GetOrCreateMappingsResponse
 */
export const GetOrCreateMappingsResponse = new GetOrCreateMappingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrCreateFunctionsRequest$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.GetOrCreateFunctionsRequest", [
            { no: 1, name: "functions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Function }
        ]);
    }
    create(value) {
        const message = { functions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.metastore.v1alpha1.Function functions */ 1:
                    message.functions.push(Function.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.metastore.v1alpha1.Function functions = 1; */
        for (let i = 0; i < message.functions.length; i++)
            Function.internalBinaryWrite(message.functions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.GetOrCreateFunctionsRequest
 */
export const GetOrCreateFunctionsRequest = new GetOrCreateFunctionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrCreateFunctionsResponse$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.GetOrCreateFunctionsResponse", [
            { no: 1, name: "functions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Function }
        ]);
    }
    create(value) {
        const message = { functions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.metastore.v1alpha1.Function functions */ 1:
                    message.functions.push(Function.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.metastore.v1alpha1.Function functions = 1; */
        for (let i = 0; i < message.functions.length; i++)
            Function.internalBinaryWrite(message.functions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.GetOrCreateFunctionsResponse
 */
export const GetOrCreateFunctionsResponse = new GetOrCreateFunctionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrCreateLocationsRequest$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.GetOrCreateLocationsRequest", [
            { no: 1, name: "locations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Location }
        ]);
    }
    create(value) {
        const message = { locations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.metastore.v1alpha1.Location locations */ 1:
                    message.locations.push(Location.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.metastore.v1alpha1.Location locations = 1; */
        for (let i = 0; i < message.locations.length; i++)
            Location.internalBinaryWrite(message.locations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.GetOrCreateLocationsRequest
 */
export const GetOrCreateLocationsRequest = new GetOrCreateLocationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrCreateLocationsResponse$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.GetOrCreateLocationsResponse", [
            { no: 1, name: "locations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Location }
        ]);
    }
    create(value) {
        const message = { locations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.metastore.v1alpha1.Location locations */ 1:
                    message.locations.push(Location.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.metastore.v1alpha1.Location locations = 1; */
        for (let i = 0; i < message.locations.length; i++)
            Location.internalBinaryWrite(message.locations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.GetOrCreateLocationsResponse
 */
export const GetOrCreateLocationsResponse = new GetOrCreateLocationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrCreateStacktracesRequest$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.GetOrCreateStacktracesRequest", [
            { no: 1, name: "stacktraces", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Stacktrace }
        ]);
    }
    create(value) {
        const message = { stacktraces: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.metastore.v1alpha1.Stacktrace stacktraces */ 1:
                    message.stacktraces.push(Stacktrace.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.metastore.v1alpha1.Stacktrace stacktraces = 1; */
        for (let i = 0; i < message.stacktraces.length; i++)
            Stacktrace.internalBinaryWrite(message.stacktraces[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.GetOrCreateStacktracesRequest
 */
export const GetOrCreateStacktracesRequest = new GetOrCreateStacktracesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOrCreateStacktracesResponse$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.GetOrCreateStacktracesResponse", [
            { no: 1, name: "stacktraces", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Stacktrace }
        ]);
    }
    create(value) {
        const message = { stacktraces: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.metastore.v1alpha1.Stacktrace stacktraces */ 1:
                    message.stacktraces.push(Stacktrace.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.metastore.v1alpha1.Stacktrace stacktraces = 1; */
        for (let i = 0; i < message.stacktraces.length; i++)
            Stacktrace.internalBinaryWrite(message.stacktraces[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.GetOrCreateStacktracesResponse
 */
export const GetOrCreateStacktracesResponse = new GetOrCreateStacktracesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnsymbolizedLocationsRequest$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.UnsymbolizedLocationsRequest", [
            { no: 1, name: "limit", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "min_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { limit: 0, minKey: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 limit */ 1:
                    message.limit = reader.uint32();
                    break;
                case /* string min_key */ 2:
                    message.minKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* uint32 limit = 1; */
        if (message.limit !== 0)
            writer.tag(1, WireType.Varint).uint32(message.limit);
        /* string min_key = 2; */
        if (message.minKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.minKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.UnsymbolizedLocationsRequest
 */
export const UnsymbolizedLocationsRequest = new UnsymbolizedLocationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnsymbolizedLocationsResponse$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.UnsymbolizedLocationsResponse", [
            { no: 1, name: "locations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Location },
            { no: 2, name: "max_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { locations: [], maxKey: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.metastore.v1alpha1.Location locations */ 1:
                    message.locations.push(Location.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string max_key */ 2:
                    message.maxKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.metastore.v1alpha1.Location locations = 1; */
        for (let i = 0; i < message.locations.length; i++)
            Location.internalBinaryWrite(message.locations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string max_key = 2; */
        if (message.maxKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.maxKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.UnsymbolizedLocationsResponse
 */
export const UnsymbolizedLocationsResponse = new UnsymbolizedLocationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateLocationLinesRequest$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.CreateLocationLinesRequest", [
            { no: 1, name: "locations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Location }
        ]);
    }
    create(value) {
        const message = { locations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.metastore.v1alpha1.Location locations */ 1:
                    message.locations.push(Location.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.metastore.v1alpha1.Location locations = 1; */
        for (let i = 0; i < message.locations.length; i++)
            Location.internalBinaryWrite(message.locations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.CreateLocationLinesRequest
 */
export const CreateLocationLinesRequest = new CreateLocationLinesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateLocationLinesResponse$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.CreateLocationLinesResponse", []);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        return target !== null && target !== void 0 ? target : this.create();
    }
    internalBinaryWrite(message, writer, options) {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.CreateLocationLinesResponse
 */
export const CreateLocationLinesResponse = new CreateLocationLinesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StacktracesRequest$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.StacktracesRequest", [
            { no: 1, name: "stacktrace_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { stacktraceIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string stacktrace_ids */ 1:
                    message.stacktraceIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string stacktrace_ids = 1; */
        for (let i = 0; i < message.stacktraceIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.stacktraceIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.StacktracesRequest
 */
export const StacktracesRequest = new StacktracesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StacktracesResponse$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.StacktracesResponse", [
            { no: 1, name: "stacktraces", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Stacktrace }
        ]);
    }
    create(value) {
        const message = { stacktraces: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.metastore.v1alpha1.Stacktrace stacktraces */ 1:
                    message.stacktraces.push(Stacktrace.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.metastore.v1alpha1.Stacktrace stacktraces = 1; */
        for (let i = 0; i < message.stacktraces.length; i++)
            Stacktrace.internalBinaryWrite(message.stacktraces[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.StacktracesResponse
 */
export const StacktracesResponse = new StacktracesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationsRequest$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.LocationsRequest", [
            { no: 1, name: "location_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { locationIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string location_ids */ 1:
                    message.locationIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string location_ids = 1; */
        for (let i = 0; i < message.locationIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.locationIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.LocationsRequest
 */
export const LocationsRequest = new LocationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationsResponse$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.LocationsResponse", [
            { no: 1, name: "locations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Location }
        ]);
    }
    create(value) {
        const message = { locations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.metastore.v1alpha1.Location locations */ 1:
                    message.locations.push(Location.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.metastore.v1alpha1.Location locations = 1; */
        for (let i = 0; i < message.locations.length; i++)
            Location.internalBinaryWrite(message.locations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.LocationsResponse
 */
export const LocationsResponse = new LocationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocationLinesRequest$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.LocationLinesRequest", [
            { no: 1, name: "location_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { locationIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string location_ids */ 1:
                    message.locationIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string location_ids = 1; */
        for (let i = 0; i < message.locationIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.locationIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.LocationLinesRequest
 */
export const LocationLinesRequest = new LocationLinesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FunctionsRequest$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.FunctionsRequest", [
            { no: 1, name: "function_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { functionIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string function_ids */ 1:
                    message.functionIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string function_ids = 1; */
        for (let i = 0; i < message.functionIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.functionIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.FunctionsRequest
 */
export const FunctionsRequest = new FunctionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FunctionsResponse$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.FunctionsResponse", [
            { no: 1, name: "functions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Function }
        ]);
    }
    create(value) {
        const message = { functions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.metastore.v1alpha1.Function functions */ 1:
                    message.functions.push(Function.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.metastore.v1alpha1.Function functions = 1; */
        for (let i = 0; i < message.functions.length; i++)
            Function.internalBinaryWrite(message.functions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.FunctionsResponse
 */
export const FunctionsResponse = new FunctionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MappingsRequest$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.MappingsRequest", [
            { no: 1, name: "mapping_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { mappingIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string mapping_ids */ 1:
                    message.mappingIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string mapping_ids = 1; */
        for (let i = 0; i < message.mappingIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.mappingIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.MappingsRequest
 */
export const MappingsRequest = new MappingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MappingsResponse$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.MappingsResponse", [
            { no: 1, name: "mappings", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Mapping }
        ]);
    }
    create(value) {
        const message = { mappings: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated parca.metastore.v1alpha1.Mapping mappings */ 1:
                    message.mappings.push(Mapping.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated parca.metastore.v1alpha1.Mapping mappings = 1; */
        for (let i = 0; i < message.mappings.length; i++)
            Mapping.internalBinaryWrite(message.mappings[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.MappingsResponse
 */
export const MappingsResponse = new MappingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Sample$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.Sample", [
            { no: 1, name: "stacktrace_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "labels", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => SampleLabel } },
            { no: 3, name: "num_labels", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => SampleNumLabel } },
            { no: 4, name: "num_units", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => SampleNumUnit } }
        ]);
    }
    create(value) {
        const message = { stacktraceId: "", labels: {}, numLabels: {}, numUnits: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stacktrace_id */ 1:
                    message.stacktraceId = reader.string();
                    break;
                case /* map<string, parca.metastore.v1alpha1.SampleLabel> labels */ 2:
                    this.binaryReadMap2(message.labels, reader, options);
                    break;
                case /* map<string, parca.metastore.v1alpha1.SampleNumLabel> num_labels */ 3:
                    this.binaryReadMap3(message.numLabels, reader, options);
                    break;
                case /* map<string, parca.metastore.v1alpha1.SampleNumUnit> num_units */ 4:
                    this.binaryReadMap4(message.numUnits, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    binaryReadMap2(map, reader, options) {
        let len = reader.uint32(), end = reader.pos + len, key, val;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = SampleLabel.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field parca.metastore.v1alpha1.Sample.labels");
            }
        }
        map[key !== null && key !== void 0 ? key : ""] = val !== null && val !== void 0 ? val : SampleLabel.create();
    }
    binaryReadMap3(map, reader, options) {
        let len = reader.uint32(), end = reader.pos + len, key, val;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = SampleNumLabel.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field parca.metastore.v1alpha1.Sample.num_labels");
            }
        }
        map[key !== null && key !== void 0 ? key : ""] = val !== null && val !== void 0 ? val : SampleNumLabel.create();
    }
    binaryReadMap4(map, reader, options) {
        let len = reader.uint32(), end = reader.pos + len, key, val;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = SampleNumUnit.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field parca.metastore.v1alpha1.Sample.num_units");
            }
        }
        map[key !== null && key !== void 0 ? key : ""] = val !== null && val !== void 0 ? val : SampleNumUnit.create();
    }
    internalBinaryWrite(message, writer, options) {
        /* string stacktrace_id = 1; */
        if (message.stacktraceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stacktraceId);
        /* map<string, parca.metastore.v1alpha1.SampleLabel> labels = 2; */
        for (let k of Object.keys(message.labels)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            SampleLabel.internalBinaryWrite(message.labels[k], writer, options);
            writer.join().join();
        }
        /* map<string, parca.metastore.v1alpha1.SampleNumLabel> num_labels = 3; */
        for (let k of Object.keys(message.numLabels)) {
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            SampleNumLabel.internalBinaryWrite(message.numLabels[k], writer, options);
            writer.join().join();
        }
        /* map<string, parca.metastore.v1alpha1.SampleNumUnit> num_units = 4; */
        for (let k of Object.keys(message.numUnits)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            SampleNumUnit.internalBinaryWrite(message.numUnits[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.Sample
 */
export const Sample = new Sample$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Stacktrace$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.Stacktrace", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "location_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: "", locationIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* repeated string location_ids */ 2:
                    message.locationIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* repeated string location_ids = 2; */
        for (let i = 0; i < message.locationIds.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.locationIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.Stacktrace
 */
export const Stacktrace = new Stacktrace$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SampleLabel$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.SampleLabel", [
            { no: 1, name: "labels", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { labels: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string labels */ 1:
                    message.labels.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string labels = 1; */
        for (let i = 0; i < message.labels.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.labels[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.SampleLabel
 */
export const SampleLabel = new SampleLabel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SampleNumLabel$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.SampleNumLabel", [
            { no: 1, name: "num_labels", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/ }
        ]);
    }
    create(value) {
        const message = { numLabels: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 num_labels */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.numLabels.push(reader.int64().toString());
                    else
                        message.numLabels.push(reader.int64().toString());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated int64 num_labels = 1; */
        if (message.numLabels.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.numLabels.length; i++)
                writer.int64(message.numLabels[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.SampleNumLabel
 */
export const SampleNumLabel = new SampleNumLabel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SampleNumUnit$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.SampleNumUnit", [
            { no: 1, name: "units", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { units: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string units */ 1:
                    message.units.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string units = 1; */
        for (let i = 0; i < message.units.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.units[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.SampleNumUnit
 */
export const SampleNumUnit = new SampleNumUnit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Location$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.Location", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "address", kind: "scalar", T: 4 /*ScalarType.UINT64*/ },
            { no: 4, name: "mapping_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "is_folded", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "lines", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Line },
            { no: 7, name: "mapping_index", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value) {
        const message = { id: "", address: "0", mappingId: "", isFolded: false, lines: [], mappingIndex: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* uint64 address */ 2:
                    message.address = reader.uint64().toString();
                    break;
                case /* string mapping_id */ 4:
                    message.mappingId = reader.string();
                    break;
                case /* bool is_folded */ 5:
                    message.isFolded = reader.bool();
                    break;
                case /* repeated parca.metastore.v1alpha1.Line lines */ 6:
                    message.lines.push(Line.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* uint32 mapping_index */ 7:
                    message.mappingIndex = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* uint64 address = 2; */
        if (message.address !== "0")
            writer.tag(2, WireType.Varint).uint64(message.address);
        /* string mapping_id = 4; */
        if (message.mappingId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.mappingId);
        /* bool is_folded = 5; */
        if (message.isFolded !== false)
            writer.tag(5, WireType.Varint).bool(message.isFolded);
        /* repeated parca.metastore.v1alpha1.Line lines = 6; */
        for (let i = 0; i < message.lines.length; i++)
            Line.internalBinaryWrite(message.lines[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* uint32 mapping_index = 7; */
        if (message.mappingIndex !== 0)
            writer.tag(7, WireType.Varint).uint32(message.mappingIndex);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.Location
 */
export const Location = new Location$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Line$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.Line", [
            { no: 1, name: "function_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "line", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 3, name: "function_index", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value) {
        const message = { functionId: "", line: "0", functionIndex: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string function_id */ 1:
                    message.functionId = reader.string();
                    break;
                case /* int64 line */ 2:
                    message.line = reader.int64().toString();
                    break;
                case /* uint32 function_index */ 3:
                    message.functionIndex = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string function_id = 1; */
        if (message.functionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.functionId);
        /* int64 line = 2; */
        if (message.line !== "0")
            writer.tag(2, WireType.Varint).int64(message.line);
        /* uint32 function_index = 3; */
        if (message.functionIndex !== 0)
            writer.tag(3, WireType.Varint).uint32(message.functionIndex);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.Line
 */
export const Line = new Line$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Function$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.Function", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "start_line", kind: "scalar", T: 3 /*ScalarType.INT64*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "system_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "filename", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "name_string_index", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "system_name_string_index", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "filename_string_index", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value) {
        const message = { id: "", startLine: "0", name: "", systemName: "", filename: "", nameStringIndex: 0, systemNameStringIndex: 0, filenameStringIndex: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* int64 start_line */ 2:
                    message.startLine = reader.int64().toString();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string system_name */ 4:
                    message.systemName = reader.string();
                    break;
                case /* string filename */ 5:
                    message.filename = reader.string();
                    break;
                case /* uint32 name_string_index */ 6:
                    message.nameStringIndex = reader.uint32();
                    break;
                case /* uint32 system_name_string_index */ 7:
                    message.systemNameStringIndex = reader.uint32();
                    break;
                case /* uint32 filename_string_index */ 8:
                    message.filenameStringIndex = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* int64 start_line = 2; */
        if (message.startLine !== "0")
            writer.tag(2, WireType.Varint).int64(message.startLine);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string system_name = 4; */
        if (message.systemName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.systemName);
        /* string filename = 5; */
        if (message.filename !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.filename);
        /* uint32 name_string_index = 6; */
        if (message.nameStringIndex !== 0)
            writer.tag(6, WireType.Varint).uint32(message.nameStringIndex);
        /* uint32 system_name_string_index = 7; */
        if (message.systemNameStringIndex !== 0)
            writer.tag(7, WireType.Varint).uint32(message.systemNameStringIndex);
        /* uint32 filename_string_index = 8; */
        if (message.filenameStringIndex !== 0)
            writer.tag(8, WireType.Varint).uint32(message.filenameStringIndex);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.Function
 */
export const Function = new Function$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Mapping$Type extends MessageType {
    constructor() {
        super("parca.metastore.v1alpha1.Mapping", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "start", kind: "scalar", T: 4 /*ScalarType.UINT64*/ },
            { no: 3, name: "limit", kind: "scalar", T: 4 /*ScalarType.UINT64*/ },
            { no: 4, name: "offset", kind: "scalar", T: 4 /*ScalarType.UINT64*/ },
            { no: 5, name: "file", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "build_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "has_functions", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "has_filenames", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "has_line_numbers", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "has_inline_frames", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "file_string_index", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 12, name: "build_id_string_index", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value) {
        const message = { id: "", start: "0", limit: "0", offset: "0", file: "", buildId: "", hasFunctions: false, hasFilenames: false, hasLineNumbers: false, hasInlineFrames: false, fileStringIndex: 0, buildIdStringIndex: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target !== null && target !== void 0 ? target : this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* uint64 start */ 2:
                    message.start = reader.uint64().toString();
                    break;
                case /* uint64 limit */ 3:
                    message.limit = reader.uint64().toString();
                    break;
                case /* uint64 offset */ 4:
                    message.offset = reader.uint64().toString();
                    break;
                case /* string file */ 5:
                    message.file = reader.string();
                    break;
                case /* string build_id */ 6:
                    message.buildId = reader.string();
                    break;
                case /* bool has_functions */ 7:
                    message.hasFunctions = reader.bool();
                    break;
                case /* bool has_filenames */ 8:
                    message.hasFilenames = reader.bool();
                    break;
                case /* bool has_line_numbers */ 9:
                    message.hasLineNumbers = reader.bool();
                    break;
                case /* bool has_inline_frames */ 10:
                    message.hasInlineFrames = reader.bool();
                    break;
                case /* uint32 file_string_index */ 11:
                    message.fileStringIndex = reader.uint32();
                    break;
                case /* uint32 build_id_string_index */ 12:
                    message.buildIdStringIndex = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* uint64 start = 2; */
        if (message.start !== "0")
            writer.tag(2, WireType.Varint).uint64(message.start);
        /* uint64 limit = 3; */
        if (message.limit !== "0")
            writer.tag(3, WireType.Varint).uint64(message.limit);
        /* uint64 offset = 4; */
        if (message.offset !== "0")
            writer.tag(4, WireType.Varint).uint64(message.offset);
        /* string file = 5; */
        if (message.file !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.file);
        /* string build_id = 6; */
        if (message.buildId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.buildId);
        /* bool has_functions = 7; */
        if (message.hasFunctions !== false)
            writer.tag(7, WireType.Varint).bool(message.hasFunctions);
        /* bool has_filenames = 8; */
        if (message.hasFilenames !== false)
            writer.tag(8, WireType.Varint).bool(message.hasFilenames);
        /* bool has_line_numbers = 9; */
        if (message.hasLineNumbers !== false)
            writer.tag(9, WireType.Varint).bool(message.hasLineNumbers);
        /* bool has_inline_frames = 10; */
        if (message.hasInlineFrames !== false)
            writer.tag(10, WireType.Varint).bool(message.hasInlineFrames);
        /* uint32 file_string_index = 11; */
        if (message.fileStringIndex !== 0)
            writer.tag(11, WireType.Varint).uint32(message.fileStringIndex);
        /* uint32 build_id_string_index = 12; */
        if (message.buildIdStringIndex !== 0)
            writer.tag(12, WireType.Varint).uint32(message.buildIdStringIndex);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.metastore.v1alpha1.Mapping
 */
export const Mapping = new Mapping$Type();
/**
 * @generated ServiceType for protobuf service parca.metastore.v1alpha1.MetastoreService
 */
export const MetastoreService = new ServiceType("parca.metastore.v1alpha1.MetastoreService", [
    { name: "GetOrCreateMappings", options: {}, I: GetOrCreateMappingsRequest, O: GetOrCreateMappingsResponse },
    { name: "GetOrCreateFunctions", options: {}, I: GetOrCreateFunctionsRequest, O: GetOrCreateFunctionsResponse },
    { name: "GetOrCreateLocations", options: {}, I: GetOrCreateLocationsRequest, O: GetOrCreateLocationsResponse },
    { name: "GetOrCreateStacktraces", options: {}, I: GetOrCreateStacktracesRequest, O: GetOrCreateStacktracesResponse },
    { name: "UnsymbolizedLocations", options: {}, I: UnsymbolizedLocationsRequest, O: UnsymbolizedLocationsResponse },
    { name: "CreateLocationLines", options: {}, I: CreateLocationLinesRequest, O: CreateLocationLinesResponse },
    { name: "Locations", options: {}, I: LocationsRequest, O: LocationsResponse },
    { name: "Functions", options: {}, I: FunctionsRequest, O: FunctionsResponse },
    { name: "Mappings", options: {}, I: MappingsRequest, O: MappingsResponse },
    { name: "Stacktraces", options: {}, I: StacktracesRequest, O: StacktracesResponse }
]);
