// Copyright 2022 The Parca Authors
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { useEffect, useState } from 'react';
var useDelayedLoader = function (isLoading, options) {
    if (isLoading === void 0) { isLoading = false; }
    var _a = (options !== null && options !== void 0 ? options : {}).delay, delay = _a === void 0 ? 500 : _a;
    var _b = useState(false), isLoaderVisible = _b[0], setIsLoaderVisible = _b[1];
    useEffect(function () {
        var showLoaderTimeout;
        if (isLoading && !isLoaderVisible) {
            // if the request takes longer than half a second, show the loading icon
            showLoaderTimeout = setTimeout(function () {
                setIsLoaderVisible(true);
            }, delay);
        }
        else if (!isLoading && isLoaderVisible) {
            setIsLoaderVisible(false);
        }
        return function () { return clearTimeout(showLoaderTimeout); };
    }, [isLoading, isLoaderVisible, delay]);
    return isLoaderVisible;
};
export default useDelayedLoader;
