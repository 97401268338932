var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { formatDate } from '@parca/functions';
import { Query, ProfileType } from '@parca/parser';
import { QueryRequest_Mode, QueryRequest_ReportType, ProfileDiffSelection_Mode, Timestamp, } from '@parca/client';
export var timeFormat = "MMM d, 'at' h:mm:s a '(UTC)'";
export var timeFormatShort = 'MMM d, h:mma';
export function ParamsString(params) {
    return Object.keys(params)
        .map(function (key) {
        return "".concat(key, "=").concat(params[key]);
    })
        .join('&');
}
export function SuffixParams(params, suffix) {
    return Object.fromEntries(Object.entries(params).map(function (_a) {
        var key = _a[0], value = _a[1];
        return ["".concat(key).concat(suffix), value];
    }));
}
export function ParseLabels(labels) {
    return labels.map(function (labelString) {
        var parts = labelString.split('=', 2);
        return { name: parts[0], value: parts[1] };
    });
}
export function ProfileSelectionFromParams(expression, from, to, merge, labels, profileName, time, filterQuery) {
    if (merge !== undefined &&
        merge === 'true' &&
        from !== undefined &&
        to !== undefined &&
        expression !== undefined) {
        return new MergedProfileSelection(parseInt(from), parseInt(to), expression, filterQuery);
    }
    if (labels !== undefined && time !== undefined && profileName !== undefined) {
        return new SingleProfileSelection(profileName, ParseLabels(labels), parseInt(time), filterQuery);
    }
    return null;
}
var SingleProfileSelection = /** @class */ (function () {
    function SingleProfileSelection(profileName, labels, time, filterQuery) {
        this.profileName = profileName;
        this.labels = labels;
        this.time = time;
        this.filterQuery = filterQuery;
    }
    SingleProfileSelection.prototype.ProfileName = function () {
        return this.profileName;
    };
    SingleProfileSelection.prototype.HistoryParams = function () {
        return {
            profile_name: this.profileName,
            labels: this.labels.map(function (label) { return "".concat(label.name, "=").concat(encodeURIComponent(label.value)); }),
            time: this.time,
        };
    };
    SingleProfileSelection.prototype.Type = function () {
        return 'single';
    };
    SingleProfileSelection.prototype.ProfileSource = function () {
        return new SingleProfileSource(this.profileName, this.labels, this.time, this.filterQuery);
    };
    return SingleProfileSelection;
}());
export { SingleProfileSelection };
var MergedProfileSelection = /** @class */ (function () {
    function MergedProfileSelection(from, to, query, filterQuery) {
        this.from = from;
        this.to = to;
        this.query = query;
        this.filterQuery = filterQuery;
    }
    MergedProfileSelection.prototype.ProfileName = function () {
        return Query.parse(this.query).profileName();
    };
    MergedProfileSelection.prototype.HistoryParams = function () {
        return {
            mode: 'merge',
            from: this.from.toString(),
            to: this.to.toString(),
            query: this.query,
        };
    };
    MergedProfileSelection.prototype.Type = function () {
        return 'merge';
    };
    MergedProfileSelection.prototype.ProfileSource = function () {
        return new MergedProfileSource(this.from, this.to, this.query, this.filterQuery);
    };
    return MergedProfileSelection;
}());
export { MergedProfileSelection };
var SingleProfileSource = /** @class */ (function () {
    function SingleProfileSource(profileName, labels, time, filterQuery) {
        this.profName = profileName;
        this.labels = labels;
        this.time = time;
        this.filterQuery = filterQuery;
    }
    SingleProfileSource.prototype.query = function () {
        var seriesQuery = this.profName +
            this.labels.reduce(function (agg, label) {
                return agg + "".concat(label.name, "=\"").concat(label.value, "\",");
            }, '{');
        return seriesQuery + '}';
    };
    SingleProfileSource.prototype.DiffSelection = function () {
        return {
            options: {
                oneofKind: 'single',
                single: {
                    time: Timestamp.fromDate(new Date(this.time)),
                    query: this.query(),
                },
            },
            mode: ProfileDiffSelection_Mode.SINGLE_UNSPECIFIED,
        };
    };
    SingleProfileSource.prototype.QueryRequest = function () {
        return {
            options: {
                oneofKind: 'single',
                single: {
                    time: Timestamp.fromDate(new Date(this.time)),
                    query: this.query(),
                },
            },
            reportType: QueryRequest_ReportType.FLAMEGRAPH_UNSPECIFIED,
            mode: QueryRequest_Mode.SINGLE_UNSPECIFIED,
            filterQuery: this.filterQuery,
        };
    };
    SingleProfileSource.prototype.ProfileType = function () {
        return ProfileType.fromString(this.profName);
    };
    SingleProfileSource.prototype.profileName = function () {
        return this.profName;
    };
    SingleProfileSource.prototype.Describe = function () {
        var profileName = this.profileName();
        return (_jsxs(_Fragment, { children: [_jsxs("p", { children: [profileName !== '' ? _jsxs("a", { children: [profileName, " profile of "] }) : '', '  ', this.labels
                            .filter(function (label) { return label.name !== '__name__'; })
                            .map(function (label) { return (_jsx("button", __assign({ type: "button", className: "inline-block rounded-lg text-gray-700 bg-gray-200 dark:bg-gray-700 dark:text-gray-400 px-2 py-1 text-xs font-bold mr-3" }, { children: "".concat(label.name, "=\"").concat(label.value, "\"") }), label.name)); })] }), _jsx("p", { children: formatDate(this.time, timeFormat) })] }));
    };
    SingleProfileSource.prototype.stringLabels = function () {
        return this.labels
            .filter(function (label) { return label.name !== '__name__'; })
            .map(function (label) { return "".concat(label.name, "=").concat(label.value); });
    };
    SingleProfileSource.prototype.toString = function () {
        return "single profile of type ".concat(this.profileName(), " with labels ").concat(this.stringLabels().join(', '), " collected at ").concat(formatDate(this.time, timeFormat));
    };
    return SingleProfileSource;
}());
export { SingleProfileSource };
var ProfileDiffSource = /** @class */ (function () {
    function ProfileDiffSource(a, b, filterQuery) {
        this.a = a;
        this.b = b;
        this.filterQuery = filterQuery;
    }
    ProfileDiffSource.prototype.DiffSelection = function () {
        throw new Error('Method not implemented.');
    };
    ProfileDiffSource.prototype.QueryRequest = function () {
        return {
            options: {
                oneofKind: 'diff',
                diff: {
                    a: this.a.DiffSelection(),
                    b: this.b.DiffSelection(),
                },
            },
            reportType: QueryRequest_ReportType.FLAMEGRAPH_UNSPECIFIED,
            mode: QueryRequest_Mode.DIFF,
            filterQuery: this.filterQuery,
        };
    };
    ProfileDiffSource.prototype.ProfileType = function () {
        return this.a.ProfileType();
    };
    ProfileDiffSource.prototype.Describe = function () {
        return (_jsx(_Fragment, { children: _jsx("p", { children: "Browse the comparison" }) }));
    };
    ProfileDiffSource.prototype.toString = function () {
        return "".concat(this.a.toString(), " compared with ").concat(this.b.toString());
    };
    return ProfileDiffSource;
}());
export { ProfileDiffSource };
var MergedProfileSource = /** @class */ (function () {
    function MergedProfileSource(from, to, query, filterQuery) {
        this.from = from;
        this.to = to;
        this.query = query;
        this.filterQuery = filterQuery;
    }
    MergedProfileSource.prototype.DiffSelection = function () {
        return {
            options: {
                oneofKind: 'merge',
                merge: {
                    start: Timestamp.fromDate(new Date(this.from)),
                    end: Timestamp.fromDate(new Date(this.to)),
                    query: this.query,
                },
            },
            mode: ProfileDiffSelection_Mode.MERGE,
        };
    };
    MergedProfileSource.prototype.QueryRequest = function () {
        return {
            options: {
                oneofKind: 'merge',
                merge: {
                    start: Timestamp.fromDate(new Date(this.from)),
                    end: Timestamp.fromDate(new Date(this.to)),
                    query: this.query,
                },
            },
            reportType: QueryRequest_ReportType.FLAMEGRAPH_UNSPECIFIED,
            mode: QueryRequest_Mode.MERGE,
            filterQuery: this.filterQuery,
        };
    };
    MergedProfileSource.prototype.ProfileType = function () {
        return ProfileType.fromString(Query.parse(this.query).profileName());
    };
    MergedProfileSource.prototype.Describe = function () {
        return (_jsxs("a", { children: ["Merge of \"", this.query, "\" from ", formatDate(this.from, timeFormat), " to", ' ', formatDate(this.to, timeFormat)] }));
    };
    MergedProfileSource.prototype.toString = function () {
        return "merged profiles of query \"".concat(this.query, "\" from ").concat(formatDate(this.from, timeFormat), " to ").concat(formatDate(this.to, timeFormat));
    };
    return MergedProfileSource;
}());
export { MergedProfileSource };
