// Copyright 2022 The Parca Authors
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
var _a;
import { createSlice } from '@reduxjs/toolkit';
// Define the initial state using that type
var initialState = {
    compare: false,
    searchNodeString: undefined,
    filterByFunction: undefined,
};
export var profileSlice = createSlice({
    name: 'profile',
    initialState: initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        setCompare: function (state, action) {
            state.compare = action.payload;
        },
        setSearchNodeString: function (state, action) {
            state.searchNodeString = action.payload;
        },
        setFilterByFunction: function (state, action) {
            state.filterByFunction = action.payload;
        },
    },
});
export var setCompare = (_a = profileSlice.actions, _a.setCompare), setSearchNodeString = _a.setSearchNodeString, setFilterByFunction = _a.setFilterByFunction;
// Other code such as selectors can use the imported `RootState` type
export var selectCompareMode = function (state) { return state.profile.compare; };
export var selectSearchNodeString = function (state) {
    return state.profile.searchNodeString;
};
export var selectFilterByFunction = function (state) {
    return state.profile.filterByFunction;
};
export default profileSlice.reducer;
