var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// Copyright 2022 The Parca Authors
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
// http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { createContext, useContext } from 'react';
import Spinner from '../Spinner';
export var defaultValue = {
    loader: _jsx(Spinner, {}),
    perf: {
        onRender: function () { },
        markInteraction: function () { },
    },
};
var ParcaContext = createContext(defaultValue);
export var ParcaContextProvider = function (_a) {
    var children = _a.children, value = _a.value;
    return _jsx(ParcaContext.Provider, __assign({ value: value !== null && value !== void 0 ? value : defaultValue }, { children: children }));
};
export var useParcaContext = function () {
    var context = useContext(ParcaContext);
    if (context == null) {
        return defaultValue;
    }
    return context;
};
export default ParcaContext;
